import { useEffect } from 'react';
import { ProductInfo } from '../domain-models/product-info.model';
import { onLoadProducts } from '../orchestration/onLoadProducts';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';
import { AppState } from '../store/application-reducer';

export function useLoadProductList(
  state: AppState,
  dispatch: React.Dispatch<ApplicationActions>,
  isProductListVisible: boolean,
  isProductHome: boolean,
  productList: ProductInfo[]
) {
  useEffect(() => {
    if (isProductListVisible || isProductHome) {
      if (productList?.length > 0) {
        dispatch({ type: AppActionTypes.PRODUCTS_LOADED, payload: productList });
      } else {
        onLoadProducts(dispatch, state.productList, isProductListVisible, isProductHome);
      }
      !isProductHome && dispatch({ type: AppActionTypes.SHOW_PRODUCT_LIST, payload: isProductListVisible });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductListVisible, isProductHome]);
}
