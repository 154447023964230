import { fetchProducts } from '../api-interaction/fetch-product-list';
import { ProductInfo } from '../domain-models/product-info.model';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';

export async function onLoadProducts(
  dispatch: React.Dispatch<ApplicationActions>,
  productList: ProductInfo[],
  loadProducts: boolean,
  loadHome = false
): Promise<void> {
  if ((!loadProducts || productList?.length > 0) && !loadHome) {
    dispatch({ type: AppActionTypes.SHOW_PRODUCT_LIST, payload: loadProducts });
    return;
  }
  dispatch({ type: AppActionTypes.LOAD_PRODUCT_LIST });
  const response = await fetchProducts();
  if (response.error || !response.data) {
    if (response?.error?.fault?.status === '401') {
      dispatch({ type: AppActionTypes.SET_USER_UNAUTHORIZED, payload: true });
    }
    dispatch({ type: AppActionTypes.LOADING_PRODUCTS_FAILED });
    return;
  }
  dispatch({ type: AppActionTypes.SHOW_PRODUCT_LIST, payload: loadProducts });
  dispatch({ type: AppActionTypes.PRODUCTS_LOADED, payload: response.data });
}
