import styles from './Modal.module.css';

type ModalProps = {
  heading: string;
  testId?: string;
  isOpen?: boolean;
  children?: React.ReactNode;
  footer?: JSX.Element;
  onClose: () => void;
};
export const Modal = ({ testId, isOpen, children, heading, footer }: ModalProps): JSX.Element => {
  return (
    <div>
      <div className={`modal-backdrop fade ${styles.glassContainer} ${isOpen ? 'show' : ''}`}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="modal-dialog modal-dialog-centered"
          role="document"
          data-testid={testId}
        >
          <div className={`modal-content ${styles.dialogContent}`}>
            <div className={`modal-header ${styles.dialogBorders}`}>
              <h5 className={`modal-title ${styles.dialogTitle}`} id="modal-title-1">
                {heading}
              </h5>
            </div>
            <div className="modal-body" data-testid={`${testId}-body`}>
              {isOpen && children}
            </div>
            {footer !== undefined && <div className={`modal-footer ${styles.dialogBorders}`}>{footer}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
