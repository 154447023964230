export function readSessionStorage(key: string): string | null {
  return sessionStorage.getItem(key);
}

export function setSessionStorage(key: string, value: any) {
  sessionStorage.setItem(key, value);
}

export function updateSessionStorage(sessions: { key: string; value: any }[]) {
  sessions?.forEach((session) => {
    if (session.value) {
      setSessionStorage(session.key, session.value);
    }
  });
}

export function deleteSessionStorage(key: string) {
  sessionStorage.removeItem(key);
}

export function getSessionHeaders(): Record<string, string> {
  const sessionParams = [
    { key: 'userId', headerName: 'x-user-id', value: sessionStorage.getItem('userId') },
    { key: 'authzIdp', headerName: 'x-authz-idp', value: sessionStorage.getItem('authzIdp') },
    { key: 'userSource', headerName: 'x-user-source', value: sessionStorage.getItem('userSource') },
  ];

  return sessionParams.reduce((headers, param) => {
    if (param.value) {
      headers[param.headerName] = param.value;
    }
    return headers;
  }, {} as Record<string, string>);
}
