import { useEffect } from 'react';
import { onLoadHistory } from '../orchestration/onLoadHistory';
import { ApplicationActions } from '../store/application-actions';
import { AppState } from '../store/application-reducer';

export function useHistoryLoading(state: AppState, dispatch: React.Dispatch<ApplicationActions>) {
  useEffect(() => {
    const isAssistantLoaded = state.productConversations.some(
      (product) => product.agent.id === state.selectedAssistant
    );
    // Only call onLoadHistory if the selectedAssistant is not present in productConversations
    if (!isAssistantLoaded && state.selectedAssistant) {
      onLoadHistory(dispatch, state.selectedAssistant, state.selectedAssistantDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedAssistant]);
}
