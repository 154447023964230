import { ProductInfo } from 'features/chat/src/domain-models/product-info.model';
import { ConversationSummary } from '../../../domain-models/conversation-history.model';
import { ReactComponent as Chat } from '../../icons/chat.svg';
import { HistoryItemMenu } from '../history-item-menu/HistoryItemMenu';
import styles from './HistoryItem.module.css';

export interface SelectableHistoryItemProps {
  item: ConversationSummary;
  activeItemId?: string;
  agent: ProductInfo;
  onSelect: (id: string, agent: ProductInfo) => void;
  onDelete: (id: string) => void;
  onSetToEditMode: () => void;
}

export const SelectableHistoryItem = ({
  item,
  activeItemId,
  agent,
  onSelect,
  onDelete,
  onSetToEditMode,
}: SelectableHistoryItemProps): JSX.Element => {
  const isActiveItem = item.session_id === activeItemId;
  let activeClassName = styles.historyItem;
  let iconClassName = '';
  if (isActiveItem) {
    activeClassName += ' ' + styles.activeIndicatorActive;
    iconClassName = styles.historyItemIconActive;
  }

  return (
    <li className={`${activeClassName} d-flex align-items-center`} data-testid="history-item">
      <div
        className={`${styles.historyItemBtn} d-flex align-items-center`}
        onClick={() => onSelect(item.session_id, agent)}
        data-testid="history-item-select-btn"
      >
        <div className={iconClassName} data-testid="history-item-active-indicator">
          <Chat />
        </div>
        <div className={styles.historyItemText}>{item.title.length >= 20 ? `${item.title}...` : item.title}</div>
      </div>
      <div>
        <HistoryItemMenu
          id={`historyItemMenu${item.session_id}`}
          isActive={isActiveItem}
          onClickEdit={() => onSetToEditMode()}
          onClickDelete={() => onDelete(item.session_id)}
        />
      </div>
    </li>
  );
};
