import { editConversation } from '../api-interaction/edit-conversation';
import { ConversationSummary } from '../domain-models/conversation-history.model';
import { ProductInfo } from '../domain-models/product-info.model';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';
import { onLoadHistory } from './onLoadHistory';

export async function onEditConversation(
  conversationSummary: ConversationSummary,
  assistantCode: string,
  assistantDetails = {} as ProductInfo,
  dispatch: React.Dispatch<ApplicationActions>
): Promise<void> {
  await editConversation(conversationSummary.title, assistantCode, conversationSummary.session_id);
  dispatch({ type: AppActionTypes.EDIT_CONVERSATION_TITLE, payload: conversationSummary });
  await onLoadHistory(dispatch, assistantCode, assistantDetails);
}
