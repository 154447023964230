import { fetchHistory } from '../api-interaction/fetch-history';
import { ProductInfo } from '../domain-models/product-info.model';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';

export async function onLoadHistory(
  dispatch: React.Dispatch<ApplicationActions>,
  assistantCode: string,
  assistantDetails?: ProductInfo
): Promise<void> {
  dispatch({ type: AppActionTypes.LOAD_USER_PRODUCTS });
  const response = await fetchHistory(assistantCode);
  if (response.error || !response.data) {
    if (response?.error?.fault?.status === '401') {
      dispatch({ type: AppActionTypes.SET_USER_UNAUTHORIZED, payload: true });
    }
    dispatch({ type: AppActionTypes.LOADING_USER_PRODUCTS_FAILED });
    return;
  }
  dispatch({ type: AppActionTypes.LOADING_USER_PRODUCTS_FAILED });
  assistantDetails?.name &&
    dispatch({
      type: AppActionTypes.LOAD_PRODUCT_CONVERSATIONS,
      payload: { agent: assistantDetails, ...response.data },
    });
}
