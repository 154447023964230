import { ModusDropdown } from '@trimble-oss/modus-react-components';
import { IconButton } from '../../common/icon-button/IconButton';
import { ReactComponent as Delete } from '../../icons/delete.svg';
import { ReactComponent as FileEdit } from '../../icons/file-edit.svg';
import { ReactComponent as MoreHorizontal } from '../../icons/more-horizontal.svg';
import styles from './HistoryItemMenu.module.css';

export interface HistoryItemMenuProps extends HistoryItemMenuContentProps {
  id: string;
  isActive: boolean;
}

interface HistoryItemMenuContentProps {
  onClickEdit: () => void;
  onClickDelete: () => void;
}

export const HistoryItemMenu = ({ id, isActive, onClickEdit, onClickDelete }: HistoryItemMenuProps): JSX.Element => {
  return (
    <ModusDropdown toggleElementId={id}>
      <button
        id={id}
        className={`btn btn-icon-only ${styles.moreButton} ${isActive ? styles.selectedItem : styles.unselectedItem}`}
        data-testid="history-item-more-btn"
        slot="dropdownToggle"
      >
        <MoreHorizontal />
      </button>
      <HistoryItemMenuContent onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
    </ModusDropdown>
  );
};

const HistoryItemMenuContent = ({ onClickEdit, onClickDelete }: HistoryItemMenuContentProps): JSX.Element => {
  return (
    <div className={`shadow ${styles.moreButtonMenu}`} slot="dropdownList">
      <IconButton size="normal" onClick={() => onClickEdit()} testId="history-item-menu-edit-btn">
        <FileEdit />
      </IconButton>
      <IconButton size="normal" onClick={() => onClickDelete()} testId="history-item-menu-delete-btn">
        <Delete />
      </IconButton>
    </div>
  );
};
