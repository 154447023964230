import { useState } from 'react';
import { Accordion, menu } from '../../common/sidebar/sidebar';
import { ReactComponent as Down } from '../../icons/chevron-right-bold.svg';
import styles from './HistoryFooter.module.css';

export const HistoryFooter = (): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((showMenu) => !showMenu);
  };
  return (
    <div className={`d-block ${styles.historyFooter} align-items-center`} data-testid="history-footer">
      <div className={`d-flex ${styles.menuFooter}`} onClick={toggleMenu}>
        <div>{showMenu ? 'Show Less' : 'Show More'}</div>
        <Down
          style={
            showMenu
              ? { transform: `rotate(-90deg)`, transition: 'transform 0.3s' }
              : { transform: `rotate(90deg)`, transition: 'transform 0.3s' }
          }
        />
      </div>
      {showMenu && (
        <ul className={styles.menuList}>
          {menu.map((item) => (
            <Accordion key={item.id} item={item} />
          ))}
        </ul>
      )}
    </div>
  );
};
