export enum PromptActionTypes {
  UpdatePrompt = 'UPDATE_PROMPT',
  ResetPrompt = 'RESET_PROMPT',
  SetPromptHistoryIndex = 'SET_PROMPT_HISTORY_INDEX',
  UpdatePromptHistory = 'UPDATE_PROMPT_HISTORY',
}

export type UPDATE_PROMPT = {
  type: PromptActionTypes.UpdatePrompt;
  payload: string;
};

export type RESET_PROMPT = {
  type: PromptActionTypes.ResetPrompt;
};
export type SET_PROMPT_HISTORY_INDEX = {
  type: PromptActionTypes.SetPromptHistoryIndex;
  payload: number;
};

export type UPDATE_PROMPT_HISTORY = {
  type: PromptActionTypes.UpdatePromptHistory;
  payload: string[];
};

export type PromptActions = UPDATE_PROMPT | RESET_PROMPT | SET_PROMPT_HISTORY_INDEX | UPDATE_PROMPT_HISTORY;
