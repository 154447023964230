import { readSessionStorage } from '@tda/utilities';
import { useEffect } from 'react';
import { ProductInfo } from '../domain-models/product-info.model';
import { onLoadHistory } from '../orchestration/onLoadHistory';
import { onLoadProductsByUserId } from '../orchestration/onLoadProductsByUser';
import { ApplicationActions } from '../store/application-actions';
import { AppState } from '../store/application-reducer';

export function useLoadProductsFromStorage(
  state: AppState,
  dispatch: React.Dispatch<ApplicationActions>,
  userId: string | null
) {
  useEffect(() => {
    if (userId) {
      onLoadProductsByUserId(dispatch, userId, state.productConversations).then(() => {
        const storedProductsString = readSessionStorage('userProducts') ?? '';
        const storedProducts = storedProductsString.split(',').filter(Boolean) ?? [];
        // when userProducts are passed as query parameters
        if (state.userProducts.length === null && storedProducts.length !== 0) {
          storedProducts.forEach((storedProduct: string) => {
            const product = state.productList?.find((p: ProductInfo) => p.id === storedProduct);
            if (product) {
              onLoadHistory(dispatch, product.id, product);
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
}
