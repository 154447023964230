import { updateUserProducts } from '../api-interaction/update-user-products';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';

export async function onUpdateUserProducts(
  products: string[],
  dispatch: React.Dispatch<ApplicationActions>
): Promise<void> {
  dispatch({ type: AppActionTypes.LOAD_USER_PRODUCTS });
  const { data } = await updateUserProducts(products);
  if (data) {
    dispatch({ type: AppActionTypes.USER_PRODUCTS_LOADED, payload: data });
  } else {
    dispatch({ type: AppActionTypes.LOADING_USER_PRODUCTS_FAILED });
    return;
  }
}
