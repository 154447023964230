import { useEffect } from 'react';
import { ProductInfo } from '../domain-models/product-info.model';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';

export function useLoadAssistantFromUrl(dispatch: React.Dispatch<ApplicationActions>, agent: ProductInfo | null) {
  useEffect(() => {
    if (agent) {
      dispatch({ type: AppActionTypes.SET_AGENT_DETAILS, payload: agent });
      dispatch({ type: AppActionTypes.LOAD_AGENT, payload: agent.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent?.id]);
}
