import { TrimbleUser } from '@technology/auth-code-grant-provider';
import { ModusSpinner } from '@trimble-oss/modus-react-components';
import { Interlocutor } from '../../domain-models/conversation-entry.model';
import { Conversation as ConversationModel } from '../../domain-models/conversation.model';
import { ProductInfo } from '../../domain-models/product-info.model';
import { ApplicationActions } from '../../store/application-actions';
import styles from './Conversation.module.css';
import { AgentConversationEntry } from './agent/AgentConversationEntry';
import { HumanConversationEntry } from './human/HumanConversationEntry';

export type ConversationProps = {
  actionButtons?: ((codeSnippet: string) => JSX.Element)[];
  conversation: ConversationModel;
  loading: boolean;
  errors: string[];
  user: TrimbleUser;
  dispatch: React.Dispatch<ApplicationActions>;
  assistant: ProductInfo | null;
};

export const Conversation = ({
  conversation,
  loading,
  errors,
  user,
  actionButtons = [],
  dispatch,
  assistant,
}: ConversationProps): JSX.Element => {
  return (
    <div className={styles.tdaConversationContainer}>
      {loading && (
        <div className="d-flex justify-content-center">
          <ModusSpinner data-testid="spinner" />
        </div>
      )}
      {!loading &&
        assistant &&
        conversation.messages.length > 0 &&
        conversation.messages.map((entry) => {
          return entry.interlocutor_type === Interlocutor.Agent ? (
            <AgentConversationEntry
              assistant={assistant}
              dispatch={dispatch}
              key={entry.id}
              entry={entry}
              errors={errors}
              actionButtons={actionButtons}
            />
          ) : (
            <HumanConversationEntry key={entry.id} entry={entry} user={user} />
          );
        })}
    </div>
  );
};
