import { deleteConversation } from '../api-interaction/delete-conversation';
import { ProductInfo } from '../domain-models/product-info.model';
import { ApplicationActions } from '../store/application-actions';
import { onLoadHistory } from './onLoadHistory';

export async function onDeleteConversation(
  sessionId: string,
  assistantCode: string,
  assistantDetails = {} as ProductInfo,
  dispatch: React.Dispatch<ApplicationActions>
): Promise<void> {
  await deleteConversation(assistantCode, sessionId);
  await onLoadHistory(dispatch, assistantCode, assistantDetails);
}
