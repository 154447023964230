import { ReactComponent as Agent } from '../../icons/agent.svg';
import styles from './AgentLogo.module.css';

type AgentLogoProps = {
  sizeClass?: string;
};

export const AgentLogo = ({ sizeClass }: AgentLogoProps) => {
  return (
    <div className={`${styles.agentLogoWrapper}`}>
      <Agent className={sizeClass || styles.agentLogo} />
    </div>
  );
};
