// Interlocutor is the person or entity that is participating in the conversation.
export enum Interlocutor {
  Agent = 'ai',
  Human = 'human',
}

export class ConversationEntry {
  constructor(init?: Partial<ConversationEntry>) {
    Object.assign(this, init);
  }

  public id = '';
  public session_id = '';
  public interlocutor_id = '';
  public parent_id = '';
  public message = '';
  public likes = 0;
  public dislikes = 0;
  public comments = '';
  public interlocutor_type: Interlocutor = Interlocutor.Human;
  public timestamp: number = new Date().getTime();
  public agent_code: string = 'generic';
}
