import { ProductInfo } from 'features/chat/src/domain-models/product-info.model';
import { useState } from 'react';
import { ConversationSummary } from '../../../domain-models/conversation-history.model';
import { EditableHistoryItem } from './EditableHistoryItem';
import { SelectableHistoryItem } from './SelectableHistoryItem';

export interface HistoryItemProps {
  item: ConversationSummary;
  activeItemId?: string;
  agent: ProductInfo;
  onSelect: (id: string, agent: ProductInfo) => void;
  onEdit: (editedValue: ConversationSummary) => void;
  onDelete: (id: string) => void;
}

export const HistoryItem = ({
  item,
  activeItemId,
  agent,
  onSelect,
  onEdit,
  onDelete,
}: HistoryItemProps): JSX.Element => {
  const [isEditState, setIsEditState] = useState(false);

  const onAcceptEdit = (value: string) => {
    onEdit({ ...item, title: value });
    setIsEditState(false);
  };

  return isEditState ? (
    <EditableHistoryItem
      item={item}
      activeItemId={activeItemId}
      agent={agent}
      onAccept={(value) => onAcceptEdit(value)}
      onReject={(_) => setIsEditState(false)}
    />
  ) : (
    <SelectableHistoryItem
      item={item}
      activeItemId={activeItemId}
      agent={agent}
      onSelect={onSelect}
      onSetToEditMode={() => setIsEditState(true)}
      onDelete={onDelete}
    />
  );
};
