import styles from './IconButton.module.css';

type IconButtonProps = {
  size?: 'small' | 'normal' | 'large';
  testId?: string;
  children: React.ReactNode;
  onClick: () => void;
};
export function IconButton({ size, testId, children, onClick }: IconButtonProps): JSX.Element {
  let btnSizeClass = '';
  let iconSizeClass = '';
  if (size === 'small') {
    btnSizeClass = `btn-sm`;
    iconSizeClass = `${styles.iconSmall}`;
  } else if (size === 'large') {
    btnSizeClass = 'btn-lg';
    iconSizeClass = `${styles.iconLarge}`;
  }
  return (
    <button
      data-testid={testId}
      onClick={onClick}
      type="button"
      className={`btn btn-icon-only btn-text-dark tda-btn-text ${btnSizeClass} p-0`}
    >
      <i className={`${styles.iconContainer} ${iconSizeClass}`} aria-hidden="true">
        {children}
      </i>
    </button>
  );
}
