export function getSearchParam(key: string): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}

export function getSearchParamsString(): string {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.toString();
}

export function deleteSearchParams(keys: string[]): void {
  const searchParams = new URLSearchParams(window.location.search);
  for (const key of keys) {
    searchParams.delete(key);
  }

  const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
  window.history.replaceState(null, '', newUrl);
}

export function buildUpdatedUrl(refreshParams: Record<string, string | null>): string {
  const url = new URL(window.location.href);
  Object.keys(refreshParams).forEach((key) => {
    if (refreshParams[key]) {
      url.searchParams.set(key, refreshParams[key]!);
    } else {
      url.searchParams.delete(key);
    }
  });
  return url.toString();
}
