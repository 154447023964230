export const agentMenus = {
  agents: [
    {
      displayName: 'AI Video and Safety',
      value: 'aivideoandsafety',
      id: 1,
      items: [
        {
          displayName: 'Video Intelligence',
          value: 'videointelligence',
          id: 1,
        },
      ],
    },
    {
      displayName: 'Asset Maintenance',
      value: 'assetmaintenance',
      id: 2,
      items: [
        {
          displayName: 'TMT Fleet Maintenance and Service Center',
          value: 'tmtfleetmaintenance',
          id: 1,
        },
        {
          displayName: 'Trimble Connected Maintenance',
          value: 'trimbleconnectedmaintenance',
          id: 2,
        },
        {
          displayName: 'Trimble ELD DVIR',
          value: 'trimbleelddvir',
          id: 3,
        },
      ],
    },
    {
      displayName: 'Fleet Management and Compliance',
      value: 'fleetmanagementandcompliance',
      id: 3,
      items: [
        {
          displayName: 'Instinct',
          value: 'instinct',
          id: 1,
        },
        {
          displayName: 'Trimble App Manager',
          value: 'trimbleappmanager',
          id: 2,
        },
        {
          displayName: 'Trimble ELD',
          value: 'trimbleeld',
          id: 3,
        },
        {
          displayName: 'Trimble Fleet Manager',
          value: 'trimblefleetmanager',
          id: 4,
        },
        {
          displayName: 'PMobile',
          value: 'PMobile',
          id: 5,
        },
        {
          displayName: 'Media Manager',
          value: 'mediamanager',
          id: 6,
        },
      ],
    },
    {
      displayName: 'Freight Procurement',
      value: 'freightprocurement',
      id: 4,
      items: [
        {
          displayName: 'Engage Bid',
          value: 'engagebid',
          id: 1,
        },
        {
          displayName: 'Trimble Engage Lane',
          value: 'trimbleengagelane',
          id: 2,
        },
      ],
    },
    {
      displayName: 'Route Optimization and Navigation',
      value: 'routeoptimizationandnavigation',
      id: 5,
      items: [
        {
          displayName: 'Account Manager',
          value: 'accountmanager',
          id: 1,
        },
        {
          displayName: 'Appian Daily Planner',
          value: 'appiandailyplanner',
          id: 2,
        },
        {
          displayName: 'CoPilot',
          value: 'copilot',
          id: 3,
        },
        {
          displayName: 'Customer Communication Manager',
          value: 'customercommunicationmanager',
          id: 4,
        },
        {
          displayName: 'Direct Route',
          value: 'directroute',
          id: 5,
        },
        {
          displayName: 'DRTrack',
          value: 'drtrack',
          id: 6,
        },
        {
          displayName: 'Map Data',
          value: 'mapdata',
          id: 7,
        },
        {
          displayName: 'MapExact',
          value: 'mapexact',
          id: 8,
        },
        {
          displayName: 'Maps SDK and Web APIs',
          value: 'mapssdkandwebapis',
          id: 9,
        },
        {
          displayName: 'MapSure',
          value: 'mapsure',
          id: 10,
        },
        {
          displayName: 'MileOn',
          value: 'mileon',
          id: 11,
        },
        {
          displayName: 'PC_MILER On Prem',
          value: 'pcmileronprem',
          id: 12,
        },
        {
          displayName: 'PC_MILER Rail',
          value: 'pcmilerrail',
          id: 13,
        },
        {
          displayName: 'PC_MILER Web',
          value: 'pcmilerweb',
          id: 14,
        },
        {
          displayName: 'Resource Calendar',
          value: 'resourcecalendar',
          id: 15,
        },
        {
          displayName: 'RouteReporter',
          value: 'routereporter',
          id: 16,
        },
        {
          displayName: 'TerritoryManager',
          value: 'territorymanager',
          id: 17,
        },
      ],
    },
    {
      displayName: 'Network Strategy',
      value: 'networkstrategy',
      id: 6,
      items: [
        {
          displayName: 'Trimble Network Insights',
          value: 'trimblenetworkinsights',
          id: 1,
        },
      ],
    },
    {
      displayName: 'Transportation Management',
      value: 'transportationmanagement',
      id: 7,
      items: [
        {
          displayName: 'Fuel Dispatch',
          value: 'fueldispatch',
          id: 1,
        },
        {
          displayName: 'Inventory Services',
          value: 'inventoryservices',
          id: 2,
        },
        {
          displayName: 'LTL Operations',
          value: 'ltloperations',
          id: 3,
        },
        {
          displayName: 'TMWSuite',
          value: 'tmwsuites',
          id: 4,
        },
        {
          displayName: 'TMW Back Office',
          value: 'tmwbackoffice',
          id: 5,
        },
        {
          displayName: 'TMW Operations',
          value: 'tmwoperations',
          id: 6,
        },
        {
          displayName: 'TMWSuite D2Link',
          value: 'tmwsuited2link',
          id: 7,
        },
        {
          displayName: 'TMWSuite File Maintenance',
          value: 'tmwsuitefilemaintenance',
          id: 8,
        },
        {
          displayName: 'TMWSuite Freight Board Interface',
          value: 'tmwsuitefreightboardinterface',
          id: 9,
        },
        {
          displayName: 'TMWSuite Interface Settings',
          value: 'tmwsuiteinterfacesettings',
          id: 10,
        },
        {
          displayName: 'TMWSuite SSRS Report Library',
          value: 'tmwsuitessrsreportlibrary',
          id: 11,
        },
        {
          displayName: 'TMWSuite SystemsLink',
          value: 'tmwsuitesystemslink',
          id: 12,
        },
        {
          displayName: 'TMWSuite SystemsLink RestFul API',
          value: 'tmwsuitesystemslinkrestfulapi',
          id: 13,
        },
        {
          displayName: 'TMWSuite Trimble eManifest',
          value: 'tmwsuitetrimbleemanifest',
          id: 14,
        },
        {
          displayName: 'TMWSuite WorkCycle',
          value: 'tmwsuiteworkcycle',
          id: 15,
        },
        {
          displayName: 'Total Mail',
          value: 'totalmail',
          id: 16,
        },
        {
          displayName: 'Trimble Web Products',
          value: 'trimblewebproducts',
          id: 17,
        },
        {
          displayName: 'TruckMate',
          value: 'truckmate',
          id: 18,
        },
        {
          displayName: 'TruckMate D2Link',
          value: 'truckmated2link',
          id: 19,
        },
        {
          displayName: 'Innovative',
          value: 'innovative',
          id: 20,
        },
      ],
    },
  ],
};
export const appDefaultMenu = () => {
  const agent = agentMenus?.agents?.[0]?.items?.[0];
  return { id: agent.value, name: agent.displayName, description: '', priority_status: false };
};
