import { ModusSpinner } from '@trimble-oss/modus-react-components';
import { ProductInfo } from 'features/chat/src/domain-models/product-info.model';
import { AgentLogo } from '../../common/agent-logo/AgentLogo';
import styles from './CompassHome.module.css';

type HomeProps = {
  agentName?: string;
  selectAgentMode?: boolean;
  loading: boolean;
  productList: ProductInfo[];
  onAgentClick: (item: ProductInfo) => void;
  onProductButtonClick: () => void;
};

export const CompassHome = ({
  agentName,
  selectAgentMode,
  productList,
  loading,
  onAgentClick,
  onProductButtonClick,
}: HomeProps): JSX.Element => {
  const agentsCondensedList = productList?.filter((product) => product?.priority_status === true);
  const loadHome = !agentName || selectAgentMode;
  return (
    <div
      className={`d-flex flex-column align-items-center text-center ${styles.headerContainer}`}
      data-testid="homePage"
    >
      {loadHome ? (
        <>
          {loading ? (
            <div data-testid="home-products-loading-spinner" className={`${styles.spinnerContainer}`}>
              <ModusSpinner />
            </div>
          ) : (
            <>
              <div className="d-flex flex-column align-items-center">
                <AgentLogo />
                <h1 className={`text-center ${styles.headerText} `}>Hello! Welcome to Trimble Compass.</h1>
                <h2 className={`text-center ${styles.subheaderText} `}>
                  If you use one of these popular products, just click to get started.
                </h2>
              </div>
              <div className="d-block pb-4">
                {agentsCondensedList.map((item) => (
                  <button
                    key={item.id}
                    type="button"
                    className={`m-2 ${styles.agentButtonContainer}`}
                    onClick={() => {
                      onAgentClick(item);
                    }}
                  >
                    {item.name}
                  </button>
                ))}
                <div>
                  <button
                    type="button"
                    className={`mt-3 p-2 ${styles.productButtonContainer}`}
                    onClick={() => onProductButtonClick()}
                  >
                    I don’t see my product above, show me the full list
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <AgentLogo />
          <h1 className={`text-center ${styles.headerText} `}>Hello! You’re speaking with</h1>
          <h1 className={`text-center ${styles.headerText} p-0 `}>{agentName}</h1>
        </div>
      )}
    </div>
  );
};
