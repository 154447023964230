import { HttpResponse } from '@tda/utilities';
import { PromptResponse } from '../domain-models/prompt-response.model';
import { ChatClient } from './chat-http-client';
import { ChatStreamClient } from './chat-stream-client';

export async function sendPrompt(
  message: string,
  assistantCode: string,
  conversationId?: string
): Promise<HttpResponse<PromptResponse>> {
  return await ChatClient.post<PromptResponse>(`/assistants/${assistantCode}/messages`, {
    session_id: conversationId,
    message: message,
  });
}
export async function sendPromptStream(
  message: string,
  assistantCode: string,
  conversationId = '',
  onMessage: (promptResponse: PromptResponse) => void,
  onError: (error: unknown) => void,
  onClose: () => void
): Promise<void> {
  return new Promise((resolve, reject) => {
    let promptResponse: PromptResponse | undefined = undefined;
    ChatStreamClient.stream(
      `/assistants/${assistantCode}/messages`,
      {
        session_id: conversationId,
        message: message,
        stream: true,
      },
      (event) => {
        if (event.event === 'metadata') {
          promptResponse = JSON.parse(event.data) as PromptResponse;
          onMessage({ ...promptResponse, message: '' });
          resolve();
        } else if (event.event === 'error') {
          onError(event.data);
          reject(event.data);
        } else if (promptResponse) {
          const decodedMessage = decodeURIComponent(event.data) || '\n';
          if (onMessage) onMessage({ ...promptResponse, message: decodedMessage });
        }
      },
      onError,
      onClose
    );
  });
}
