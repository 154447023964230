import { formatDate } from '@tda/utilities';
import { ProductInfo } from 'features/chat/src/domain-models/product-info.model';
import { ApplicationActions } from 'features/chat/src/store/application-actions';
import { ConversationEntry } from '../../../domain-models/conversation-entry.model';
import { LikeDislikeComment } from '../../../domain-models/conversation-sentiment-scale';
import { onUpdateConversation } from '../../../orchestration/onUpdateConversation';
import { AgentLogo } from '../../common/agent-logo/AgentLogo';
import { CopyButton } from '../../common/copy-button/CopyButton';
import { IconButton } from '../../common/icon-button/IconButton';
import { ReactComponent as Alert } from '../../icons//alert.svg';
import { ReactComponent as Dots } from '../../icons/dots.svg';
import { ReactComponent as ThumbsDown } from '../../icons/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '../../icons/thumbs-up.svg';
import styles from './AgentConversationEntry.module.css';
import { MarkdownParser } from './Markdown';

type AgentConversationEntryProps = {
  entry: ConversationEntry;
  errors: string[];
  actionButtons?: ((codeSnippet: string) => JSX.Element)[];
  dispatch: React.Dispatch<ApplicationActions>;
  assistant: ProductInfo;
};

export const AgentConversationEntry = ({
  entry,
  errors,
  actionButtons,
  dispatch,
  assistant,
}: AgentConversationEntryProps): JSX.Element => {
  const hasError = errors.includes(entry.parent_id);
  const likesDislikes = {
    likes: entry?.likes && entry.likes > 0 ? 1 : 0,
    dislikes: entry?.dislikes && entry.dislikes > 0 ? 1 : 0,
    comments: entry?.comments ? entry.comments : '',
  };
  return (
    <div data-testid="agent-entry" className={styles.responseContainer}>
      <div style={{ flex: '0 0 30px' }} className="pr-2">
        <AgentLogo sizeClass={styles.agentLogoSize} />
      </div>
      <div className="w-100">
        <Header assistant={assistant} timestamp={entry.timestamp} data-testid="agent-header" />
        {!entry.message && !hasError && <Thinking />}
        {!entry.message && hasError && <Error />}
        {entry.message && (
          <div data-testid="agent-response" className={styles.agentResponse}>
            <MarkdownParser text={entry.message} actionButtons={actionButtons} />
          </div>
        )}
        {entry.message && (
          <Footer
            text={entry.message}
            selectedAssistant={assistant}
            messageId={entry.id}
            likeDislikes={likesDislikes}
            dispatch={dispatch}
          />
        )}
      </div>
    </div>
  );
};

function Error(): JSX.Element {
  return (
    <div data-testid="error-indicator" className="d-flex">
      <p className="mb-0 mt-1 mr-2">There seems to be an error.</p>
      <Alert className="size-small" fill="var(--tda-warning)" />
    </div>
  );
}

function Thinking(): JSX.Element {
  return (
    <div data-testid="thinking-indicator" className="d-flex align-items-center">
      <Dots className="mr-1" />
      Thinking...
    </div>
  );
}

function Header({ timestamp, assistant }: { timestamp: number; assistant: ProductInfo }): JSX.Element {
  return (
    <div
      style={{ height: '30px' }}
      data-testid="agent-header"
      className="d-flex justify-content-between align-items-center w-100 "
    >
      <h4 className="mb-0 tda-heading-text">{assistant.name}</h4>
      <label className={`${styles.dateLabel} m-0`}>{formatDate(timestamp)}</label>
    </div>
  );
}

function Footer({
  messageId,
  likeDislikes,
  dispatch,
  selectedAssistant,
  text,
}: {
  text: string;
  messageId: string;
  likeDislikes: LikeDislikeComment;
  dispatch: React.Dispatch<ApplicationActions>;
  selectedAssistant: ProductInfo;
}): JSX.Element {
  const { likes, dislikes } = likeDislikes;
  const liked = likes && likes > 0;
  const disliked = dislikes && dislikes > 0;
  return (
    <div data-testid="agent-footer" className="d-flex w-100 mb-3">
      <IconButton
        onClick={() => {
          const incrementLike = (likeDislikes: LikeDislikeComment) => {
            return {
              ...likeDislikes,
              likes: 1,
              dislikes: 0,
            };
          };
          const likeDislikeObject = incrementLike(likeDislikes);
          onUpdateConversation(selectedAssistant?.id, messageId, likeDislikeObject, dispatch);
        }}
        size="small"
      >
        <ThumbsUp className={`${styles.thumb} ${styles.horizontalFlip}  ${liked ? styles.liked : ''}`} />
      </IconButton>
      <IconButton
        onClick={() => {
          const incrementDislike = (likeDislikes: LikeDislikeComment) => {
            return {
              ...likeDislikes,
              likes: 0,
              dislikes: 1,
            };
          };
          const likeDislikeObject = incrementDislike(likeDislikes);
          onUpdateConversation(selectedAssistant?.id, messageId, likeDislikeObject, dispatch);
        }}
        size="small"
      >
        <ThumbsDown className={`${styles.thumb} ${styles.horizontalFlip}  ${disliked ? styles.liked : ''}`} />
      </IconButton>
      <CopyButton textToCopy={text} />
    </div>
  );
}
