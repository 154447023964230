import { HttpResponse } from '@tda/utilities';
import { ChatClient } from './chat-http-client';
import { LikeDislikeComment } from '../domain-models/conversation-sentiment-scale';

export async function updateConversation(
  assistantCode: string,
  messageId: string,
  likeDislike: LikeDislikeComment
): Promise<HttpResponse<LikeDislikeComment>> {
  return await ChatClient.patch<LikeDislikeComment>(`/assistants/${assistantCode}/messages/${messageId}`, likeDislike);
}
