import { Modal } from '@tda/components';

interface ConfirmModalProps {
  isOpen: boolean;
  text: string;
  testId: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmModal = ({ isOpen, text, testId, onCancel, onConfirm }: ConfirmModalProps) => {
  return (
    isOpen && (
      <Modal
        heading="Confirm"
        testId={testId}
        isOpen={isOpen}
        onClose={onCancel}
        footer={
          <>
            <button
              type="button"
              className="btn tda-btn-outlined"
              onClick={onCancel}
              data-testid={`${testId}-cancel-btn`}
            >
              Close
            </button>
            <button
              type="button"
              className="btn tda-btn-primary"
              onClick={onConfirm}
              data-testid={`${testId}-accept-btn`}
            >
              OK
            </button>
          </>
        }
      >
        {text}
      </Modal>
    )
  );
};
