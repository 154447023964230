import { copyToClipboard } from '@tda/utilities';
import { ReactComponent as Copy } from '../../icons/copy.svg';
import { IconButton } from '../icon-button/IconButton';

type CopyButtonProps = {
  textToCopy: string;
};
export const CopyButton = ({ textToCopy }: CopyButtonProps) => {
  return (
    <IconButton
      onClick={() => {
        copyToClipboard(textToCopy);
      }}
      size='small'
    >
      <Copy />
    </IconButton>
  );
};
