import { useState } from 'react';
import { ReactComponent as Down } from '../../icons/chevron-right-bold.svg';
import styles from './sidebar.module.css';

export type MenuItem = {
  id: string;
  name: string;
  mail?: boolean;
  url?: string;
  child: Menu[] | [];
};
export type Menu = {
  id: string;
  name: string;
  mail: boolean;
  url: string;
};

export const menu: MenuItem[] = [
  {
    id: 'contacts-main-1',
    name: 'Contacts',
    child: [
      {
        id: 'contacts-sub-1',
        name: 'Sales',
        mail: true,
        url: 'transportation@trimble.com',
      },
      {
        id: 'contacts-sub-2',
        name: 'Questions',
        mail: true,
        url: 'tt_learn@trimble.com',
      },
    ],
  },
  {
    id: 'tickets-main-1',
    name: 'Support Tickets',
    mail: false,
    url: 'https://crmtransportation.my.site.com/service/login?ec=302&startURL=/service/s/',
    child: [],
  },
  {
    id: 'learn-portal-main-1',
    name: 'Learn Portal',
    child: [
      {
        id: 'learn-portal-sub-1',
        name: 'Enterprise Learning Center',
        mail: false,
        url: 'http://learn.transportation.trimble.com/',
      },
      {
        id: 'learn-portal-sub-2',
        name: 'Maps Learning Center',
        mail: false,
        url: 'https://maps.trimble.com/support/',
      },
      {
        id: 'learn-portal-sub-3',
        name: 'Mobility Learning Center',
        mail: false,
        url: 'https://crmtransportation.force.com/service/login?ec=302&startURL=/service/s/',
      },
      {
        id: 'learn-portal-sub-4',
        name: 'Trimble LMS',
        mail: false,
        url: 'http://learn.trimble.com/',
      },
    ],
  },
  {
    id: 'feedback-main-1',
    mail: false,
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSfPt-SM82kq9gP5Vkw7GQ6m3_Q4bqvftbvD_uINUIjfjsUdnA/viewform',
    name: 'Feedback',
    child: [],
  },
  {
    id: 'help-main-1',
    mail: false,
    url: 'https://learn.transportation.trimble.com/compass/#tips',
    name: 'Help with this tool',
    child: [],
  },
];

export const Accordion = ({ item }: { item: MenuItem }) => {
  const [open, setOpen] = useState(false);
  return (
    <li
      className={`${styles.list}`}
      key={item.id}
      onClick={(e) => {
        e.preventDefault();
        if (item.child.length === 0) {
          if (item.mail) {
            window.open(`mailto:${item.url}`, '_blank');
            return;
          }
          window.open(item.url, '_blank');
          return;
        }
        setOpen(!open);
      }}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (item.child.length > 0) {
            setOpen(!open);
          } else if (item.mail) {
            window.open(`mailto:${item.url}`, '_blank');
          } else {
            window.open(item.url, '_blank');
          }
        }}
      >
        {item.name}{' '}
        {item.child.length > 0 ? (
          <Down
            style={
              open
                ? { transform: `rotate(-90deg)`, transition: 'transform 0.3s' }
                : { transform: `rotate(90deg)`, transition: 'transform 0.3s' }
            }
          />
        ) : null}
      </button>
      {open && item.child.length > 0 && (
        <ul className={`${styles.listContainer}`}>
          {item.child.map((child: Menu) => {
            // Explicitly define the type of 'child' parameter as 'any'
            return (
              <li
                key={child.id}
                className={`${styles.list}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (child.mail) {
                    window.open(`mailto:${child.url}`, '_blank');
                    return;
                  }
                  window.open(child.url, '_blank');
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (child.mail) {
                      window.open(`mailto:${child.url}`, '_blank');
                    } else {
                      window.open(child.url, '_blank');
                    }
                  }}
                >
                  {child.name}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

export const Sidebar = ({ inInvalidAgentPage }: { inInvalidAgentPage?: boolean }) => {
  return (
    <div className={`${styles.sidebarContainer} ${inInvalidAgentPage ? styles.absoluteContainer : ''}`}>
      <ul className={`${styles.listContainer}`}>
        {menu.map((item) => {
          return <Accordion item={item} />;
        })}
      </ul>
    </div>
  );
};
