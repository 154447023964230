import { useTheming } from '@tda/chat';
import React from 'react';
import styles from './Loading.module.css';

function Loading(): JSX.Element {
  const { selectedTheme } = useTheming(document.documentElement);

  return (
    <div
      className={styles.loadingPage}
      data-testid="not-logged-in-loading"
      style={{
        display: 'flex',
        height: '100dvh',
        width: '100dvw',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img width={'300px'} style={selectedTheme === 'dark' ? { filter: 'brightness(9999%) saturate(0)' } : {}} src="../assets/logo.svg" alt="Trimble logo" />
    </div>
  );
}
export default React.memo(Loading);
