import { ConversationSummary } from 'features/chat/src/domain-models/conversation-history.model';
import { ProductConversationHistory, ProductInfo } from 'features/chat/src/domain-models/product-info.model';
import { useEffect, useState } from 'react';
import { ReactComponent as Down } from '../../icons/chevron-right.svg';
import { HistoryItem } from '../history-item/HistoryItem';
import styles from './HistoryAccordion.module.css';

export interface AccordionProps {
  activeConversationId?: string;
  product: ProductConversationHistory;
  onSelectConversation: (id: string, agent: ProductInfo) => void;
  onEditConversation: (editedValue: ConversationSummary) => void;
  onDeleteConversation: (id: string) => void;
  onStartConversation: (agent: ProductInfo) => void;
}

const HistoryAccordion = ({
  product,
  activeConversationId,
  onSelectConversation,
  onEditConversation,
  onDeleteConversation,
  onStartConversation,
}: AccordionProps): JSX.Element => {
  const activeConversationIncluded = product.sessions.some((session) => session.session_id === activeConversationId);
  const [open, setOpen] = useState(activeConversationIncluded ?? false);

  useEffect(() => {
    if (product.sessions.some((session) => session.session_id === activeConversationId)) {
      setOpen(true);
    }
  }, [activeConversationId, product.sessions]);
  return (
    <li className={`${styles.list}`} key={product.agent.id} data-testid="history-accordion">
      <div className={`${styles.headerBar}`}>
        <button
          className={`p-0 ${styles.listHeader}`}
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
        >
          <Down
            style={
              open
                ? { transform: `rotate(90deg)`, transition: 'transform 0.3s' }
                : { transform: `rotate(-90deg)`, transition: 'transform 0.3s' }
            }
          />

          {product.agent.name}
        </button>
        <button className={`btn btn-tertiary ${styles.newButton}`} onClick={() => onStartConversation(product.agent)}>
          New Chat
        </button>
      </div>
      {open && product.sessions.length > 0 && (
        <ul className={`${styles.historyList} list-group list-group-borderless`}>
          {product.sessions.map((item) => (
            <HistoryItem
              key={item.session_id}
              item={item}
              activeItemId={activeConversationId}
              agent={product.agent}
              onSelect={onSelectConversation}
              onEdit={onEditConversation}
              onDelete={onDeleteConversation}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default HistoryAccordion;
