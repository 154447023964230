import { Theme, readLocalStorage, readSessionStorage } from '@tda/utilities';
import { useEffect, useState } from 'react';

export function useTheming(htmlElement: HTMLElement) {
  const [selectedTheme, setSelectedTheme] = useState<Theme>(initialTheme());
  const [element] = useState<HTMLElement>(htmlElement);

  const setTheme = (theme: Theme) => {
    if (theme === selectedTheme) {
      return;
    }
    element.setAttribute('tda-theme', theme);
    localStorage.setItem('tda-theme-preference', theme);
    setSelectedTheme(theme);
  };

  useEffect(() => {
    element.setAttribute('tda-theme', selectedTheme);
  }, []);
  return { selectedTheme, setTheme };
}

function initialTheme() {
  const themeParameter = readSessionStorage('userTheme') as Theme;
  if (themeParameter && isSupportedTheme(themeParameter)) {
    return themeParameter;
  }
  return (readLocalStorage('tda-theme-preference') as Theme) || Theme.Light;
}

function isSupportedTheme(theme: any) {
  return [Theme.Light, Theme.Dark].includes(theme);
}
