import { EventSourceMessage, fetchEventSource, getSessionHeaders } from '@tda/utilities';

export class ChatStreamClient {
  private static baseUrl = '';
  private static tokenProvider?: () => Promise<string>;

  public static setInstance(baseUrl: string, tokenProvider?: () => Promise<string>) {
    this.baseUrl = baseUrl;
    this.tokenProvider = tokenProvider;
  }

  public static async stream(
    path: string,
    body: Record<string, unknown>,
    onMessage: (message: EventSourceMessage) => void,
    onError: (error: unknown) => void,
    onClose: () => void
  ) {
    const headers = await ChatStreamClient.buildHeaders();
    fetchEventSource(`${ChatStreamClient.baseUrl}${path}`, {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
      onmessage: onMessage,
      onerror: onError,
      onclose: onClose,
    });
  }

  private static async buildHeaders(): Promise<Record<string, string>> {
    let token = '';
    if (typeof ChatStreamClient.tokenProvider === 'function') {
      token = await ChatStreamClient.tokenProvider();
    }
    // Add session storage headers
    const sessionHeaders = getSessionHeaders();
    return {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
      Authorization: `Bearer ${token}`,
      'Cache-Control': 'no-transform',
      ...sessionHeaders,
    };
  }
}
