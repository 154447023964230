import { formatDate } from '@tda/utilities';
import { TrimbleUser } from '@technology/auth-code-grant-provider';
import { ConversationEntry } from '../../../domain-models/conversation-entry.model';
import { CopyButton } from '../../common/copy-button/CopyButton';
import styles from './HumanConversationEntry.module.css';
import { ReactComponent as Avatar } from './avatar.svg';

type HumanConversationEntryProps = {
  entry: ConversationEntry;
  user: TrimbleUser;
};

export const HumanConversationEntry = ({ entry, user }: HumanConversationEntryProps): JSX.Element => {
  return (
    <div data-testid="human-entry" className="d-flex w-100">
      <div className="pr-2">
        {user.pictureUrl && <img className={styles.userImageSize} src={user.pictureUrl} alt="The user avatar." />}
        {!user.pictureUrl && (
          <Avatar style={{ width: '32px', height: '32px', borderRadius: '50%' }} className={styles.userAvatar} />
        )}
      </div>
      <div className="w-100">
        <Header entry={entry} />
        <div className="d-flex w-100">
          <p
            style={{ flexGrow: 1, wordBreak: 'break-word' }}
            data-testid="prompt-paragraph"
            className={`pb-2 m-0 ${styles.promptParagraph}`}
          >
            {entry.message}
          </p>

          <div>
            <CopyButton textToCopy={entry.message} />
          </div>
        </div>
      </div>
    </div>
  );
};

function Header({ entry }: { entry: ConversationEntry }) {
  return (
    <div
      data-testid="user-header"
      style={{ height: '30px' }}
      className="d-flex justify-content-between align-items-center w-100"
    >
      <h4 className="m-0 tda-heading-text">You</h4>
      <div>
        <label className={`${styles.dateLabel} m-0`}>{formatDate(entry.timestamp)}</label>
      </div>
    </div>
  );
}
