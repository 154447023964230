import useMediaQuery from '@mui/material/useMediaQuery';
import { readSessionStorage } from '@tda/utilities';
import { ModusSpinner } from '@trimble-oss/modus-react-components';
import { ConversationSummary } from '../../domain-models/conversation-history.model';
import { ProductConversationHistory, ProductInfo } from '../../domain-models/product-info.model';
import styles from './History.module.css';
import HistoryAccordion from './history-accordion/HistoryAccordion';
import { HistoryFooter } from './history-footer/HistoryFooter';

export interface HistoryProps {
  activeConversationId?: string;
  loading: boolean;
  productConversations: ProductConversationHistory[];
  onSelectConversation: (id: string, agent: ProductInfo) => void;
  onEditConversation: (editedValue: ConversationSummary) => void;
  onDeleteConversation: (id: string) => void;
  onStartProductConversation: (agent: ProductInfo) => void;
  onManageProducts: () => void;
}

export const History = ({
  loading,
  activeConversationId,
  productConversations,
  onSelectConversation,
  onEditConversation,
  onDeleteConversation,
  onStartProductConversation,
  onManageProducts,
}: HistoryProps): JSX.Element => {
  const isMD = useMediaQuery('(min-width:1200px)');
  const isSM = useMediaQuery('(max-width:930px)');
  const storedProducts = readSessionStorage('userProducts') ?? null;
  return (
    <div className={`${styles.historyContainer} d-flex flex-column`}>
      <div className={`${styles.headerContainer}`}>
        <div className={`pl-3 pt-2 pb-2 font-weight-bold ${styles.historyHeader}`}>
          {storedProducts ? 'History' : 'Products'}
        </div>
        <button
          className={`btn btn-outline-tertiary ${styles.productButton} ${storedProducts ? `d-none` : ``}`}
          onClick={() => onManageProducts()}
        >
          {isMD ? 'Add/Remove products' : 'Add/Remove'}
        </button>
      </div>
      {loading ? (
        <div className={`${styles.historyListContainer} d-flex justify-content-center p-3`}>
          <ModusSpinner data-testid="history-loading" size="16px" color="#fff" />
        </div>
      ) : (
        <div className={`${styles.historyListContainer}`}>
          <ul className={`${styles.historyList} list-group list-group-borderless`}>
            {productConversations.map((product) => (
              <HistoryAccordion
                key={product.agent.id}
                product={product}
                activeConversationId={activeConversationId}
                onSelectConversation={onSelectConversation}
                onEditConversation={onEditConversation}
                onDeleteConversation={onDeleteConversation}
                onStartConversation={onStartProductConversation}
              />
            ))}
          </ul>
        </div>
      )}
      {isSM && !storedProducts && <HistoryFooter />}
    </div>
  );
};
