import { ConversationHistory, ConversationSummary } from '../domain-models/conversation-history.model';
import { LikeDislikeComment } from '../domain-models/conversation-sentiment-scale';
import { Conversation } from '../domain-models/conversation.model';
import { ProductConversationHistory, ProductInfo } from '../domain-models/product-info.model';
import { PromptResponse } from '../domain-models/prompt-response.model';

export type sentimentScaleUpdate = {
  messageId: string;
  likeDislikeComment: LikeDislikeComment;
};

// Conversation actions
export type START_CONVERSATION = {
  type: AppActionTypes.START_CONVERSATION;
};
export type LIKE_DISLIKE_CONVERSATION = {
  type: AppActionTypes.LIKE_DISLIKE_CONVERSATION;
  payload: sentimentScaleUpdate;
};

export type LOAD_CONVERSATION = {
  type: AppActionTypes.LOAD_CONVERSATION;
  payload: string;
};

export type CONVERSATION_LOADED = {
  type: AppActionTypes.CONVERSATION_LOADED;
  payload: Conversation;
};

export type LOADING_CONVERSATION_FAILED = {
  type: AppActionTypes.LOADING_CONVERSATION_FAILED;
};

export type SET_CONVERSATION_TO_DELETE = {
  type: AppActionTypes.SET_CONVERSATION_TO_DELETE;
  payload: string;
};

export type EDIT_CONVERSATION_TITLE = {
  type: AppActionTypes.EDIT_CONVERSATION_TITLE;
  payload: ConversationSummary;
};

//Product actions
export type START_PRODUCT_CONVERSATION = {
  type: AppActionTypes.START_PRODUCT_CONVERSATION;
  payload: ProductInfo;
};

export type LOAD_PRODUCT_CONVERSATIONS = {
  type: AppActionTypes.LOAD_PRODUCT_CONVERSATIONS;
  payload: ProductConversationHistory;
};

export type LOAD_PRODUCT_HOME = {
  type: AppActionTypes.LOAD_PRODUCT_HOME;
};

export type SHOW_PRODUCT_LIST = {
  type: AppActionTypes.SHOW_PRODUCT_LIST;
  payload: boolean;
};

export type LOAD_PRODUCT_LIST = {
  type: AppActionTypes.LOAD_PRODUCT_LIST;
};

export type PRODUCTS_LOADED = {
  type: AppActionTypes.PRODUCTS_LOADED;
  payload: Array<ProductInfo>;
};

export type LOADING_PRODUCTS_FAILED = {
  type: AppActionTypes.LOADING_PRODUCTS_FAILED;
};

export type LOAD_USER_PRODUCTS = {
  type: AppActionTypes.LOAD_USER_PRODUCTS;
};

export type USER_PRODUCTS_LOADED = {
  type: AppActionTypes.USER_PRODUCTS_LOADED;
  payload: Array<ProductInfo>;
};

export type LOADING_USER_PRODUCTS_FAILED = {
  type: AppActionTypes.LOADING_USER_PRODUCTS_FAILED;
};

export type REMOVE_PRODUCT = {
  type: AppActionTypes.REMOVE_PRODUCT;
  payload: string;
};

// Prompt actions
export type ADD_PROMPT = {
  type: AppActionTypes.ADD_PROMPT;
  payload: string;
};

export type RESPONSE_RECEIVED = {
  type: AppActionTypes.RESPONSE_RECEIVED;
  payload: PromptResponse;
};

export type PARTIAL_RESPONSE_RECEIVED = {
  type: AppActionTypes.PARTIAL_RESPONSE_RECEIVED;
  payload: PromptResponse;
};

export type RESPONSE_COMPLETED = {
  type: AppActionTypes.RESPONSE_COMPLETED;
};

export type LOADING_RESPONSE_FAILED = {
  type: AppActionTypes.LOADING_RESPONSE_FAILED;
};

// History actions
export type LOAD_HISTORY = {
  type: AppActionTypes.LOAD_HISTORY;
};

export type HISTORY_LOADED = {
  type: AppActionTypes.HISTORY_LOADED;
  payload: ConversationHistory;
};

export type LOADING_HISTORY_FAILED = {
  type: AppActionTypes.LOADING_HISTORY_FAILED;
};

export type TOGGLE_HISTORY = {
  type: AppActionTypes.TOGGLE_HISTORY;
};
export type TOGGLE_RIGHT_PANE = {
  type: AppActionTypes.TOGGLE_RIGHT_PANE;
  payload: boolean;
};

export type LOAD_AGENT = {
  type: AppActionTypes.LOAD_AGENT;
  payload: string;
};

export type SET_AGENT_DETAILS = {
  type: AppActionTypes.SET_AGENT_DETAILS;
  payload: ProductInfo;
};

export type SET_USER_UNAUTHORIZED = {
  type: AppActionTypes.SET_USER_UNAUTHORIZED;
  payload: boolean;
};

export enum AppActionTypes {
  START_CONVERSATION = 'start_conversation',
  START_PRODUCT_CONVERSATION = 'start_product_conversation',
  LOAD_PRODUCT_CONVERSATIONS = 'load_product_conversations',
  LOAD_PRODUCT_HOME = 'load_product_home',
  REMOVE_PRODUCT = 'remove_product',
  SHOW_PRODUCT_LIST = 'show_product_list',
  LOAD_PRODUCT_LIST = 'load_product_list',
  PRODUCTS_LOADED = 'products_loaded',
  LOADING_PRODUCTS_FAILED = 'loading_products_failed',
  LOAD_USER_PRODUCTS = 'load_user_products',
  USER_PRODUCTS_LOADED = 'user_products_loaded',
  LOADING_USER_PRODUCTS_FAILED = 'loading_user_products_failed',
  LOAD_CONVERSATION = 'load_conversation',
  CONVERSATION_LOADED = 'conversation_loaded',
  LOADING_CONVERSATION_FAILED = 'loading_conversation_failed',
  SET_CONVERSATION_TO_DELETE = 'set_conversation_to_delete',
  EDIT_CONVERSATION_TITLE = 'edit_conversation_title',
  ADD_PROMPT = 'add_prompt',
  PARTIAL_RESPONSE_RECEIVED = 'partial_response_received',
  RESPONSE_COMPLETED = 'partial_response_completed',
  RESPONSE_RECEIVED = 'response_received',
  LOADING_RESPONSE_FAILED = 'loading_response_failed',
  LOAD_HISTORY = 'load_history',
  HISTORY_LOADED = 'sessions_loaded',
  LOADING_HISTORY_FAILED = 'loading_history_failed',
  TOGGLE_HISTORY = 'toggle_history',
  TOGGLE_RIGHT_PANE = 'toggle_right_pane',
  LOAD_AGENT = 'load_agent',
  SET_AGENT_DETAILS = 'set_agent_details',
  LIKE_DISLIKE_CONVERSATION = 'like_conversation',
  SET_USER_UNAUTHORIZED = 'set_user_unauthorized',
}

export type ApplicationActions =
  | START_CONVERSATION
  | LOAD_CONVERSATION
  | CONVERSATION_LOADED
  | LOADING_CONVERSATION_FAILED
  | SET_CONVERSATION_TO_DELETE
  | EDIT_CONVERSATION_TITLE
  | ADD_PROMPT
  | PARTIAL_RESPONSE_RECEIVED
  | RESPONSE_COMPLETED
  | RESPONSE_RECEIVED
  | LOADING_RESPONSE_FAILED
  | LOAD_HISTORY
  | HISTORY_LOADED
  | LOADING_HISTORY_FAILED
  | TOGGLE_HISTORY
  | TOGGLE_RIGHT_PANE
  | LOAD_AGENT
  | SET_AGENT_DETAILS
  | LIKE_DISLIKE_CONVERSATION
  | START_PRODUCT_CONVERSATION
  | LOAD_PRODUCT_CONVERSATIONS
  | LOAD_PRODUCT_HOME
  | LOAD_PRODUCT_LIST
  | SHOW_PRODUCT_LIST
  | PRODUCTS_LOADED
  | LOADING_PRODUCTS_FAILED
  | LOAD_USER_PRODUCTS
  | USER_PRODUCTS_LOADED
  | LOADING_USER_PRODUCTS_FAILED
  | REMOVE_PRODUCT
  | SET_USER_UNAUTHORIZED;
