import { ProductInfo } from 'features/chat/src/domain-models/product-info.model';
import { useState } from 'react';
import { ConversationSummary } from '../../../domain-models/conversation-history.model';
import { IconButton } from '../../common/icon-button/IconButton';
import { ReactComponent as CancelCircle } from '../../icons/cancel-circle.svg';
import { ReactComponent as Chat } from '../../icons/chat.svg';
import { ReactComponent as CheckCircle } from '../../icons/check-circle.svg';
import styles from './HistoryItem.module.css';

export interface EditableHistoryItemProps {
  item: ConversationSummary;
  activeItemId?: string;
  agent: ProductInfo;
  onAccept: (value: string) => void;
  onReject: (value: string) => void;
}

interface HistoryItemTextFieldProps {
  id: string;
  testId: string;
  value: string;
  onChange: (value: string) => void;
}

const HistoryItemTextField = ({ id, testId, value, onChange }: HistoryItemTextFieldProps): JSX.Element => {
  return (
    <div className={`d-flex align-items-center w-100`}>
      <div className="form-group m-0 w-100">
        <div className="input-with-icon-left">
          <input
            className={`form-control ${styles.historyItemTextField}`}
            id={id}
            data-testid={testId}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            autoComplete="off"
            autoFocus
          />
          <div className="input-icon pl-3">
            <div className={`${styles.historyItemIconActive}`}>
              <Chat />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EditableHistoryItem = ({ item, onAccept, onReject }: EditableHistoryItemProps): JSX.Element => {
  const [title, setTitle] = useState<string>(item.title);

  return (
    <li className={`${styles.historyItem} d-flex align-items-center`} data-testid="history-item">
      <HistoryItemTextField
        id={`edit-title-${item.session_id}`}
        testId="history-item-title-field"
        value={title}
        onChange={(value) => setTitle(value)}
      />
      <div className={`d-flex pl-1 ${styles.editIcons}`}>
        <IconButton size="small" onClick={() => onAccept(title)} testId="history-item-accept-edit-btn">
          <CheckCircle className={` ${styles.editIcons}`} />
        </IconButton>
        <IconButton size="small" onClick={() => onReject(title)} testId="history-item-reject-edit-btn">
          <CancelCircle className={` ${styles.editIcons}`} />
        </IconButton>
      </div>
    </li>
  );
};
