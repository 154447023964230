import { Environments } from '@tda/utilities';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  baseUrl: 'https://cloud.dev.api.trimblecloud.com/transportation/core/v1/atlas/',
  OPENID_CONFIG_URL: 'https://stage.id.trimblecloud.com/.well-known/openid-configuration',
  REQUESTED_SCOPE: 'Compass-ui',
  logoutUrl: 'https://stage.id.trimblecloud.com/oauth/logout',
  CONSUMER_KEY: '93aa6ce3-cfe0-4c7a-bae5-a36aa4c6603b',
  PROFILE_URL: 'https://iam.dev.trimble-transportation.com/',
  shouldAuthenticate: true,
  identityProvider: undefined,
  env: Environments.Dev,
};
