import { Environments } from './environments';

export const ApiUrls = {
  [Environments.Local]: 'http://127.0.0.1:8000/transportation/core/v1/atlas',
  [Environments.Dev]: 'https://cloud.dev.api.trimblecloud.com/transportation/core/v1/atlas',
  [Environments.Stage]: 'https://cloud.stage.api.trimblecloud.com/transportation/core/v1/atlas',
  [Environments.Prod]: 'https://cloud.api.trimble.com/transportation/core/v1/atlas',
};

export const StreamingApiUrls = {
  [Environments.Local]: 'http://127.0.0.1:8000/transportation/core/v1/atlas',
  [Environments.Dev]: 'https://compassapi.dev.trimble-transportation.com/core/v1/atlas',
  [Environments.Stage]: 'https://compassapi.stg.trimble-transportation.com/core/v1/atlas',
  [Environments.Prod]: 'https://compassapi.trimble-transportation.com/core/v1/atlas',
};
