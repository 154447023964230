import { isEmptyString } from '@tda/utilities';
import { ModusSpinner } from '@trimble-oss/modus-react-components';
import { useEffect, useReducer } from 'react';
import { ConversationEntry } from '../../domain-models/conversation-entry.model';
import { ReactComponent as PaperPlane } from '../icons/paper-plane.svg';
import styles from './Prompt.module.css';
import { PromptActionTypes } from './prompt-actions';
import { initialPromptState, promptReducer } from './prompt-reducer';

type PromptProps = {
  userPrompts: ConversationEntry[];
  onSendPrompt: (prompt: string) => void;
  responseLoading: boolean;
  selectedAssistant: string;
};

export const Prompt = ({ onSendPrompt, responseLoading, userPrompts, selectedAssistant }: PromptProps): JSX.Element => {
  const [state, dispatch] = useReducer(promptReducer, {
    ...initialPromptState,
    promptHistory: mapUserPrompts(userPrompts),
  });
  const maxHeight = 250; // Set your desired maximum height in px
  const inputElementId = 'prompt-input';

  useEffect(() => {
    dispatch({ type: PromptActionTypes.UpdatePromptHistory, payload: mapUserPrompts(userPrompts) });
  }, [userPrompts]);

  useEffect(() => {
    handleTextAreaResize(document.getElementById(inputElementId) as HTMLTextAreaElement);
  }, [state.prompt]);

  const handleVerticalArrow = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!state.isInputEdited) {
      dispatch({
        type: PromptActionTypes.SetPromptHistoryIndex,
        payload: event.key === 'ArrowUp' ? state.promptHistoryIndex + 1 : state.promptHistoryIndex - 1,
      });
    }
  };

  const handleSendPrompt = () => {
    if (!responseLoading && !isEmptyString(state.prompt.trim())) {
      onSendPrompt(state.prompt);
      dispatch({ type: PromptActionTypes.ResetPrompt });
    }
  };

  const handleTextAreaResize = (textarea: HTMLElement) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
  };

  return (
    <div className="d-flex" style={{ position: 'relative' }}>
      <textarea
        id={inputElementId}
        maxLength={8192}
        className={`form-control ${styles.promptInput} large`}
        data-testid="prompt-input"
        data-mwc-theme="light"
        placeholder={`Ask ${selectedAssistant ?? ''} Assistant a question`}
        onChange={(event) => {
          handleTextAreaResize(event.target);
          dispatch({ type: PromptActionTypes.UpdatePrompt, payload: event.target.value });
        }}
        onKeyDown={(event) => {
          handleKeyDown(event, handleSendPrompt, handleVerticalArrow);
        }}
        value={state.prompt}
      ></textarea>
      <button
        data-testid="prompt-button"
        className={`btn btn-lg tda-btn-text btn-icon-only ml-2 ${styles.promptButton}`}
        onClick={handleSendPrompt}
        disabled={responseLoading}
      >
        {responseLoading && <ModusSpinner data-testid="prompt-spinner" size="16px" />}
        {!responseLoading && <PaperPlane />}
      </button>
    </div>
  );
};

export default Prompt;

function mapUserPrompts(userPrompts: ConversationEntry[]): string[] {
  return userPrompts.map((entry) => entry.message).reverse();
}

const handleKeyDown = (
  event: React.KeyboardEvent<HTMLTextAreaElement>,
  enterCallback: () => void,
  arrowCallback: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault();
    enterCallback();
  } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    arrowCallback(event);
  }
};
