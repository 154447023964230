import { HttpResponse } from '@tda/utilities';
import { ChatClient } from './chat-http-client';

export async function editConversation(
  title: string,
  assistantCode: string,
  sessionId: string
): Promise<HttpResponse<string>> {
  return await ChatClient.patch<string>(`/assistants/${assistantCode}/sessions/${sessionId}`, {
    title: title,
  });
}
