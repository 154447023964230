import { uuid } from '@tda/utilities';
import { Reducer } from 'react';
import { ConversationEntry, Interlocutor } from '../domain-models/conversation-entry.model';
import { ConversationHistory } from '../domain-models/conversation-history.model';
import { Conversation } from '../domain-models/conversation.model';
import { ProductConversationHistory, ProductInfo } from '../domain-models/product-info.model';
import { AppActionTypes, ApplicationActions as AppActions } from './application-actions';

export type AppState = {
  selectedAssistantDetails?: ProductInfo;
  selectedAssistant: string;
  currentConversationId: string;
  currentConversation: Conversation;
  conversationHistory: ConversationHistory;
  productConversations: ProductConversationHistory[];
  productList: ProductInfo[];
  userProducts: ProductInfo[];
  // Loading states
  isLoadingHistory: boolean;
  isLoadingConversation: boolean;
  isLoadingResponse: boolean;
  isProductsLoading: boolean;
  isUserProductsLoading: boolean;
  // Error states
  conversationLoadingError: boolean;
  historyLoadingError: boolean;
  responseLoadingErrors: string[];
  isUnauthorized: boolean;
  // UI States
  isProductListVisible: boolean;
  isHistoryVisible: boolean;
  isRightPaneVisible: boolean;
  conversationIdToDelete: string;
};

export type AppReducer = Reducer<AppState, AppActions>;

export const initialAppState: AppState = {
  selectedAssistant: '',
  currentConversationId: uuid(),
  currentConversation: {
    pageIndex: 1,
    totalItems: 0,
    messages: [],
  },
  conversationHistory: { pageIndex: 1, totalItems: 0, sessions: [] },
  productConversations: [],
  isProductListVisible: false,
  productList: [],
  userProducts: [],
  // Loading states
  isLoadingHistory: false,
  isLoadingConversation: false,
  isLoadingResponse: false,
  isProductsLoading: false,
  isUserProductsLoading: false,
  // Error states
  conversationLoadingError: false,
  historyLoadingError: false,
  responseLoadingErrors: [],
  isUnauthorized: false,
  // UI States
  isHistoryVisible: true,
  isRightPaneVisible: false,
  conversationIdToDelete: '',
};

export const appReducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case AppActionTypes.LOAD_AGENT: {
      return {
        ...state,
        selectedAssistant: action.payload,
      };
    }
    case AppActionTypes.START_CONVERSATION: {
      return {
        ...state,
        currentConversationId: uuid(),
        currentConversation: {
          messages: [],
          pageIndex: 1,
          totalItems: 50,
        },
      };
    }
    case AppActionTypes.LOAD_CONVERSATION: {
      return {
        ...state,
        currentConversationId: action.payload,
        isLoadingConversation: true,
        conversationLoadingError: false,
      };
    }
    case AppActionTypes.CONVERSATION_LOADED: {
      return {
        ...state,
        currentConversation: action.payload,
        isLoadingConversation: false,
      };
    }
    case AppActionTypes.LOADING_CONVERSATION_FAILED: {
      return {
        ...state,
        isLoadingConversation: false,
        conversationLoadingError: true,
      };
    }
    case AppActionTypes.SET_CONVERSATION_TO_DELETE: {
      return {
        ...state,
        conversationIdToDelete: action.payload,
      };
    }
    case AppActionTypes.EDIT_CONVERSATION_TITLE: {
      return {
        ...state,
        conversationHistory: {
          ...state.conversationHistory,
          sessions: state.conversationHistory.sessions.map((session) =>
            session.session_id === action.payload.session_id ? action.payload : session
          ),
        },
      };
    }
    case AppActionTypes.LIKE_DISLIKE_CONVERSATION: {
      return {
        ...state,
        // Handle State update
        currentConversation: {
          ...state.currentConversation,
          messages: [
            ...state.currentConversation.messages.map((item) => {
              if (action.payload.messageId === item.id) {
                return {
                  ...item,
                  likes: action.payload.likeDislikeComment.likes || 0,
                  dislikes: action.payload.likeDislikeComment.dislikes || 0,
                  comments: action.payload.likeDislikeComment.comments || '',
                };
              }
              return item;
            }),
          ],
        },
      };
    }
    case AppActionTypes.ADD_PROMPT: {
      const promptId = uuid();
      return {
        ...state,
        currentConversation: {
          pageIndex: state.currentConversation.pageIndex,
          totalItems: state.currentConversation.totalItems,
          messages: [
            ...state.currentConversation.messages,
            new ConversationEntry({
              id: promptId,
              session_id: state.currentConversationId,
              message: action.payload,
            }),
            new ConversationEntry({
              id: uuid(),
              message: '',
              parent_id: promptId,
              interlocutor_type: Interlocutor.Agent,
            }),
          ],
        },
        isLoadingResponse: true,
      };
    }
    case AppActionTypes.RESPONSE_RECEIVED: {
      if (action.payload.session_id !== state.currentConversationId) {
        // We load conversations always from the server
        return {
          ...state,
          isLoadingResponse: false,
        };
      }
      state.currentConversation.messages[state.currentConversation.messages.length - 1] = new ConversationEntry({
        ...action.payload,
        interlocutor_type: Interlocutor.Agent,
      });
      return {
        ...state,
        currentConversation: {
          pageIndex: state.currentConversation.pageIndex,
          totalItems: state.currentConversation.totalItems,
          messages: [...state.currentConversation.messages],
        },
        isLoadingResponse: false,
        currentConversationId: state.currentConversationId || action.payload.session_id,
      };
    }
    case AppActionTypes.PARTIAL_RESPONSE_RECEIVED: {
      if (action.payload.session_id !== state.currentConversationId) {
        return {
          ...state,
          isLoadingResponse: false,
        };
      }
      const lastIndex = state.currentConversation.messages.length - 1;
      const updatedMessages = state.currentConversation.messages.map((message, index) => {
        if (index === lastIndex) {
          return new ConversationEntry({
            ...message,
            interlocutor_type: Interlocutor.Agent,
            message: message.message + action.payload.message,
          });
        }
        return message;
      });
      return {
        ...state,
        currentConversation: {
          pageIndex: state.currentConversation.pageIndex,
          totalItems: state.currentConversation.totalItems,
          messages: updatedMessages,
        },
        isLoadingResponse: false,
        currentConversationId: state.currentConversationId || action.payload.session_id,
      };
    }
    case AppActionTypes.RESPONSE_COMPLETED: {
      return {
        ...state,
        isLoadingResponse: false,
      };
    }
    case AppActionTypes.LOADING_RESPONSE_FAILED: {
      const associatedBotResponse = state.currentConversation.messages[state.currentConversation.messages.length - 1];
      return {
        ...state,
        responseLoadingErrors: [...state.responseLoadingErrors, associatedBotResponse.parent_id],
        isLoadingResponse: false,
      };
    }
    case AppActionTypes.LOAD_HISTORY: {
      return {
        ...state,
        isLoadingHistory: true,
        historyLoadingError: false,
      };
    }
    case AppActionTypes.HISTORY_LOADED: {
      return {
        ...state,
        isLoadingHistory: false,
        conversationHistory: action.payload,
      };
    }

    case AppActionTypes.LOADING_HISTORY_FAILED: {
      return {
        ...state,
        isLoadingHistory: false,
        historyLoadingError: true,
      };
    }
    case AppActionTypes.TOGGLE_HISTORY: {
      return {
        ...state,
        isHistoryVisible: !state.isHistoryVisible,
        isRightPaneVisible: false,
      };
    }
    case AppActionTypes.TOGGLE_RIGHT_PANE: {
      return {
        ...state,
        isRightPaneVisible: !state.isRightPaneVisible,
        isHistoryVisible: action.payload ? false : true,
      };
    }
    case AppActionTypes.SET_AGENT_DETAILS: {
      return {
        ...state,
        selectedAssistantDetails: action.payload,
      };
    }
    case AppActionTypes.START_PRODUCT_CONVERSATION: {
      return {
        ...state,
        selectedAssistant: action.payload.id,
        selectedAssistantDetails: action.payload,
        currentConversationId: uuid(),
        currentConversation: {
          messages: [],
          pageIndex: 1,
          totalItems: 50,
        },
        isLoadingResponse: false,
        isLoadingConversation: false,
      };
    }
    case AppActionTypes.LOAD_PRODUCT_HOME: {
      return {
        ...state,
        selectedAssistant: '',
        isProductListVisible: false,
        selectedAssistantDetails: {} as ProductInfo,
      };
    }
    case AppActionTypes.LOAD_PRODUCT_CONVERSATIONS: {
      const agentName = action.payload.agent.id;
      const productConversations = state.productConversations?.some(
        (conversation) => conversation.agent.id === agentName
      )
        ? state.productConversations?.map((conversation) =>
            conversation.agent.id === agentName ? action.payload : conversation
          )
        : [action.payload, ...state.productConversations];
      return {
        ...state,
        productConversations,
        isLoadingResponse: false,
        isLoadingConversation: false,
      };
    }
    case AppActionTypes.LOAD_PRODUCT_LIST: {
      return {
        ...state,
        isProductsLoading: true,
      };
    }
    case AppActionTypes.PRODUCTS_LOADED: {
      return {
        ...state,
        isProductsLoading: false,
        productList: action.payload,
      };
    }
    case AppActionTypes.LOADING_PRODUCTS_FAILED: {
      return {
        ...state,
        isProductsLoading: false,
        productList: [],
      };
    }
    case AppActionTypes.LOAD_USER_PRODUCTS: {
      return {
        ...state,
        isUserProductsLoading: true,
      };
    }
    case AppActionTypes.USER_PRODUCTS_LOADED: {
      return {
        ...state,
        isUserProductsLoading: false,
        userProducts: action.payload,
      };
    }
    case AppActionTypes.LOADING_USER_PRODUCTS_FAILED: {
      return {
        ...state,
        isUserProductsLoading: false,
        userProducts: [],
      };
    }
    case AppActionTypes.SHOW_PRODUCT_LIST: {
      return {
        ...state,
        isProductListVisible: action.payload,
      };
    }
    case AppActionTypes.REMOVE_PRODUCT: {
      const updatedProductConversations = state.productConversations?.filter(
        (conversation) => conversation.agent.id !== action.payload
      );
      const isActiveProduct = state.selectedAssistant === action.payload;
      return {
        ...state,
        productConversations: updatedProductConversations,
        ...(isActiveProduct && {
          selectedAssistant: '',
          selectedAssistantDetails: {} as ProductInfo,
        }),
      };
    }
    case AppActionTypes.SET_USER_UNAUTHORIZED: {
      return {
        ...state,
        isUnauthorized: action.payload,
      };
    }
    default:
      return state;
  }
};
