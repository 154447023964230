import { updateConversation } from '../api-interaction/update-conversation';
import { ConversationSummary } from '../domain-models/conversation-history.model';
import { LikeDislikeComment } from '../domain-models/conversation-sentiment-scale';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';

export async function onUpdateConversation(
  assistantCode: string,
  messageId: string,
  likeDislike: LikeDislikeComment,
  dispatch: React.Dispatch<ApplicationActions>
): Promise<void> {
  const { data } = await updateConversation(assistantCode, messageId, likeDislike);
  const dataModified = { likes: data?.likes ? data?.likes : 0, dislikes: data?.dislikes ? data?.dislikes : 0, comments: data?.comments ? data.comments : '' };
  dispatch({ type: AppActionTypes.LIKE_DISLIKE_CONVERSATION, payload: { messageId: messageId, likeDislikeComment: dataModified } })
}
