import { uuid } from '@tda/utilities';
import styles from './SampleQueries.module.css';

type SampleQuery = {
  query: string;
  description: string;
};

type SampleQueriesProps = {
  onQuerySelected: (query: string) => void;
};

export const SampleQueries = ({ onQuerySelected }: SampleQueriesProps): JSX.Element => {
  const sampleQueries: SampleQuery[] = [
    {
      query: 'What Trimble APIs are supported by this interface?',
      description: 'Find out what APIs are supported by this interface.',
    },
    {
      query: 'Write sample code on how to use authorization code grant with Trimble ID in C#.',
      description: 'Get sample code for your favorite programming language.',
    },
    {
      query: 'Where do I get support for Trimble APIs?',
      description: 'Explore the support options available to you.',
    },
  ];

  return (
    <div className="d-flex flex-column align-items-center text-center">
      <div className={`pt-2 ${styles.queriesContainer} d-none`}>
        {sampleQueries.map(({ query, description }) => (
          <div
            data-testid="sample-query"
            key={uuid()}
            onClick={() => {
              onQuerySelected(query);
            }}
            className={`m-3 p-3 ${styles.sampleQueryContainer}`}
          >
            <h3 className="h4 tda-heading-text">{query}</h3>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
