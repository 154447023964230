import { HttpResponse } from '@tda/utilities';
import { ConversationEntry } from '../domain-models/conversation-entry.model';
import { ChatClient } from './chat-http-client';

type ConversationDto = {
  pageIndex: number;
  totalItems: number;
  messages: { [conversationId: string]: ConversationEntry };
};

export async function fetchConversation(
  conversationId: string,
  assistantCode: string
): Promise<HttpResponse<ConversationDto>> {
  if (!assistantCode) {
    return Promise.resolve({
      data: {
        pageIndex: 0,
        totalItems: 0,
        messages: {},
      },
      error: undefined,
    });
  }
  return ChatClient.get<ConversationDto>(
    `/assistants/${assistantCode}/sessions/${conversationId}?pageIndex=1&pageSize=50`
  );
}
