// Check breakpoint here: https://modus.trimble.com/foundations/grid-and-spacing/
export enum BreakPoints {
  Small = 576,
  Medium = 768,
  Large = 992,
}

export enum ScreenSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export const detectScreenSize = (): ScreenSize => {
  const width = window.innerWidth;
  if (width <= BreakPoints.Small) {
    return ScreenSize.Small;
  } else if (width <= BreakPoints.Medium) {
    return ScreenSize.Medium;
  }
  return ScreenSize.Large;
};
