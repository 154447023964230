import useMediaQuery from '@mui/material/useMediaQuery';
import { ModusSpinner, ModusTextInput } from '@trimble-oss/modus-react-components';
import { useEffect, useState } from 'react';

import { ProductConversationHistory, ProductInfo } from '../../domain-models/product-info.model';
import { ReactComponent as SelectedCircle } from '../icons/circle-checked.svg';
import { ReactComponent as UnselectedCircle } from '../icons/circle-outlined.svg';
import styles from './ProductList.module.css';

type ProductListProps = {
  activeProductConversations?: ProductConversationHistory[];
  products: ProductInfo[];
  loading: boolean;
  onAddProduct: (item: any, products: string[]) => void;
  onRemoveProduct: (item: string, products: string[]) => void;
};

export const ProductList = ({
  loading,
  products,
  activeProductConversations,
  onAddProduct,
  onRemoveProduct,
}: ProductListProps): JSX.Element => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [productList, setProductList] = useState<ProductInfo[]>([]);
  const isMD = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    setProductList(products);
  }, [products]);

  useEffect(() => {
    if (activeProductConversations) {
      const activeProducts = activeProductConversations?.map((conversation) => conversation.agent);
      setSelectedProducts(activeProducts?.map((product) => product.id));
    }
  }, [activeProductConversations]);

  const toggleSelect = (value: string) => {
    if (selectedProducts?.includes(value)) {
      // Remove product
      const updatedProducts = selectedProducts?.filter((product) => product !== value);
      setSelectedProducts(updatedProducts);
      onRemoveProduct(value, updatedProducts);
    } else {
      // Add product
      const updatedProducts = [...selectedProducts, value];
      setSelectedProducts(updatedProducts);
      const newProduct = productList?.find((product) => product.id === value);
      newProduct && onAddProduct(newProduct, updatedProducts);
    }
  };
  return (
    <div className="d-flex flex-column align-items-center text-center" data-testid="productList">
      <h1 className={`text-center ${styles.headerText}`}>
        {isMD
          ? 'Select a product to add to your chat list. Select again to remove.'
          : 'Tap a Card to add it to your list.'}
      </h1>
      <ModusTextInput
        data-testid="productSearchInput"
        includeSearchIcon
        clearable
        placeholder="Search Products.."
        className={`${styles.searchContainer}`}
        onValueChange={(e) => {
          const searchValue = e.target.value;
          if (!searchValue) {
            setProductList(products ?? []);
            return;
          } else {
            const filteredProducts = products?.filter((product) =>
              product.name.toLowerCase().includes(searchValue.toLowerCase())
            );
            setProductList(filteredProducts);
          }
        }}
      />
      {loading ? (
        <div className={`${styles.productSpinner} d-flex justify-content-center`}>
          <ModusSpinner data-testid="product-list-loading" size="16px" />
        </div>
      ) : (
        <div className={styles.productGrid}>
          {productList.map((product) => (
            <div
              data-testid="productCard"
              key={product.id}
              className={`${styles.card} ${selectedProducts?.includes(product.id) ? styles.selectedCard : ''}`}
              onClick={() => toggleSelect(product.id)}
            >
              <div className={styles.iconContainer}>
                {selectedProducts?.includes(product.id) ? (
                  <SelectedCircle className={`${styles.selectedIcon}`} />
                ) : (
                  <UnselectedCircle />
                )}
              </div>
              <div>
                <div
                  className={`${styles.title} ${selectedProducts?.includes(product.id) ? styles.selectedTitle : ''}`}
                >
                  {product.name}
                </div>
                <div
                  className={`${styles.description} ${
                    selectedProducts?.includes(product.id) ? styles?.selectedDescription : ''
                  }`}
                >
                  {product.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
