import { useEffect } from 'react';
import { ProductInfo } from '../domain-models/product-info.model';
import { onLoadConversation } from '../orchestration/onLoadConversation';
import { onStartProductConversation } from '../orchestration/onStartConversation';
import { ApplicationActions } from '../store/application-actions';
import { AppState } from '../store/application-reducer';

export function useLoadConversationFromUrl(
  state: AppState,
  assistant: ProductInfo | null,
  dispatch: React.Dispatch<ApplicationActions>,
  sessionId?: string
) {
  useEffect(() => {
    if (sessionId) {
      onLoadConversation(
        sessionId || '',
        state.currentConversationId,
        assistant?.id ?? state.selectedAssistant,
        dispatch
      );
    } else if (!sessionId && state.currentConversationId) {
      state.selectedAssistantDetails &&
        onStartProductConversation(dispatch, assistant ?? state.selectedAssistantDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedAssistant, state.selectedAssistantDetails, sessionId]);
}
