import { Environments } from '@tda/utilities';
import { IUserInfoProvider, TrimbleUser } from '@technology/auth-code-grant-provider';
type UserInfo = {
  sub: string;
  given_name: string;
  family_name: string;
  name: string;
  roles: string[];
  has_more_roles: boolean;
  account_id: string;
  account_name: string;
  email: string;
  preferred_username: string | null;
  tcx_user_id: string;
  trimble_account_id: string | null;
};

export type ExtendedTrimbleUser = TrimbleUser & {
  sub: string;
};

// The service provider for the transportation user
export class TransportationUserProvider implements IUserInfoProvider {
  #endPoint: string;
  private readonly _endPoints: Record<string, string> = {
    [Environments.Dev]: 'https://cloud.dev.api.trimblecloud.com/transportation/api/identity/v2/oauth2/userinfo',
    [Environments.Stage]: 'https://cloud.stage.api.trimblecloud.com/transportation/api/identity/v2/oauth2/userinfo',
    [Environments.Prod]: 'https://cloud.api.trimble.com/transportation/api/identity/v2/oauth2/userinfo',
  };
  constructor(environment: string = Environments.Dev) {
    this.#endPoint = this._endPoints[environment];
  }
  async getUserInfo(trimbleAccessToken: string): Promise<ExtendedTrimbleUser> {
    const response = await fetch(this.#endPoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${trimbleAccessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const user = (await response.json()) as UserInfo;
      return this.mapToDto(user);
    }

    return {
      id: '',
      email: '',
      familyName: '',
      givenName: '',
      pictureUrl: '',
      initials: '',
      region: '',
      sub: '',
    };
  }

  private mapToDto(user: UserInfo): ExtendedTrimbleUser {
    return {
      id: user.account_id,
      email: user.email,
      familyName: user.family_name,
      givenName: user.given_name,
      pictureUrl: '',
      initials: `${user.given_name[0]}${user.family_name[0]}`,
      region: '',
      sub: user.sub,
    };
  }
}
