import { fetchUserProducts } from '../api-interaction/fetch-user-products';
import { ProductConversationHistory } from '../domain-models/product-info.model';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';
import { onLoadHistory } from './onLoadHistory';

export async function onLoadProductsByUserId(
  dispatch: React.Dispatch<ApplicationActions>,
  userId: string,
  loadedProducts: ProductConversationHistory[]
): Promise<void> {
  dispatch({ type: AppActionTypes.LOAD_USER_PRODUCTS });
  const response = await fetchUserProducts(userId);
  if (response.error || !response.data) {
    if (response?.error?.fault?.status === '401') {
      dispatch({ type: AppActionTypes.SET_USER_UNAUTHORIZED, payload: true });
    }
    dispatch({ type: AppActionTypes.LOADING_USER_PRODUCTS_FAILED });
    return;
  }
  const userProducts = response.data ?? [];
  // Filter products that are not already loaded
  const productsToLoad = userProducts.filter(
    (product) => !loadedProducts.some((loadedProduct) => loadedProduct.agent.id === product.id)
  );
  const loadHistoryPromises = productsToLoad.map((product) => onLoadHistory(dispatch, product.id, product));

  Promise.all(loadHistoryPromises)
    .then(() => {
      dispatch({ type: AppActionTypes.USER_PRODUCTS_LOADED, payload: userProducts });
    })
    .catch((error) => {
      console.error('Error loading history for products:', error);
      dispatch({ type: AppActionTypes.LOADING_USER_PRODUCTS_FAILED });
    });
}
