import { readSessionStorage } from '@tda/utilities';
import { sendPrompt, sendPromptStream } from '../api-interaction/send-prompt';
import { PromptResponse } from '../domain-models/prompt-response.model';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';
import { AppState } from '../store/application-reducer';
import { onLoadHistory } from './onLoadHistory';
import { onUpdateUserProducts } from './onUpdateUserProducts';

export async function onSendUserPrompt(
  prompt: string,
  state: AppState,
  dispatch: React.Dispatch<ApplicationActions>
): Promise<void> {
  dispatch({ type: AppActionTypes.ADD_PROMPT, payload: prompt });
  const streamingEnabled = readSessionStorage('streamingEnabled') === 'true';
  if (streamingEnabled) {
    const handleResponse = (promptResponse: PromptResponse) => {
      dispatch({
        type: AppActionTypes.PARTIAL_RESPONSE_RECEIVED,
        payload: promptResponse,
      });
    };
    const handleError = () => {
      dispatch({ type: AppActionTypes.LOADING_RESPONSE_FAILED });
    };
    const handleClose = () => {
      dispatch({ type: AppActionTypes.RESPONSE_COMPLETED });
    };
    await sendPromptStream(
      prompt,
      state.selectedAssistant,
      state.currentConversationId,
      handleResponse,
      handleError,
      handleClose
    );
  } else {
    const response = await sendPrompt(prompt, state.selectedAssistant, state.currentConversationId);
    if (response.error || !response.data) {
      dispatch({ type: AppActionTypes.LOADING_RESPONSE_FAILED });
      return;
    }
    dispatch({ type: AppActionTypes.RESPONSE_RECEIVED, payload: response.data });
  }
  if (state.currentConversation.messages.length === 0) {
    onLoadHistory(dispatch, state.selectedAssistant, state.selectedAssistantDetails);
    //Add as user product if not part of userProducts
    const isProductAdded = state.userProducts.find((product) => product.id === state.selectedAssistant);
    if (state.userProducts && !isProductAdded) {
      onUpdateUserProducts([state.selectedAssistant], dispatch);
    }
  }
}
