import { uuid } from '@tda/utilities';
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import remarkGfm from 'remark-gfm';
import { CopyButton } from '../../common/copy-button/CopyButton';
import styles from './Markdown.module.css';
import { trimbleMarkdownThemeDark } from './trimble-markdown-theme-dark';

type MarkdownParserProps = {
  text: string;
  actionButtons?: Array<(codeSnippet: string) => JSX.Element>;
};

type NodeChild = {
  type?: string;
  value?: string;
};

// Decode the href in case it's encoded with &amp;
const decodeHtmlEntities = (text: string) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};

export const MarkdownParser = ({ text, actionButtons = [] }: MarkdownParserProps): JSX.Element => {
  // Memoize the action buttons so that they are not re-rendered on every change.
  const MemoizedActionButtons = (codeSnippet: string, buttons: Array<(codeSnippet: string) => JSX.Element>) => {
    const memo = useMemo(() => {
      return buttons.map((actionButton) => (
        <React.Fragment key={uuid()}>{actionButton(codeSnippet || '')}</React.Fragment>
      ));
    }, [buttons, codeSnippet]);

    return memo;
  };

  return (
    <div className={`${styles.markdownContainer}`}>
      <Markdown
        data-testid="markdown-parser"
        remarkPlugins={[[remarkGfm]]}
        components={{
          a: ({ node, ...props }) => {
            const { href } = props;
            return (
              <a href={href ? decodeHtmlEntities(href) : href} target="_blank" rel="noopener noreferrer">
                {props.children}
              </a>
            );
          },
          code(props) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || '');
            const codeSnippet = (node?.children[0] as NodeChild)?.value;
            return match && match[1] ? (
              <>
                <div
                  style={{
                    background: 'var(--tda-gray-0)',
                    color: 'var(--tda-text)',
                    fontFamily: 'var(--font-family-sans-serif)',
                  }}
                  className={`${styles.codeHeader} d-flex align-items-center justify-content-between`}
                >
                  <label className={`${styles.languageLabel} text-uppercase font-weight-bold m-0`}>{match[1]}</label>
                  <div className="d-flex align-items-center justify-content-center">
                    {MemoizedActionButtons(codeSnippet || '', actionButtons)}
                    {codeSnippet && (
                      <div className="d-flex align-items-center">
                        <CopyButton textToCopy={codeSnippet} />
                      </div>
                    )}
                  </div>
                </div>
                <SyntaxHighlighter
                  className="m-0"
                  data-testid="syntax-highlighter"
                  {...rest}
                  ref={undefined}
                  children={String(children).replace(/\n$/, '')}
                  style={trimbleMarkdownThemeDark}
                  language={match[1]}
                  PreTag="div"
                />
              </>
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {text}
      </Markdown>
    </div>
  );
};
