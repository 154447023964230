import { HttpResponse } from '@tda/utilities';
import { ConversationHistory } from '../domain-models/conversation-history.model';
import { ChatClient } from './chat-http-client';

export async function fetchHistory(assistantCode: string): Promise<HttpResponse<ConversationHistory>> {
  if (!assistantCode) {
    return Promise.resolve({
      data: {
        pageIndex: 0,
        totalItems: 0,
        sessions: [],
      },
      error: undefined,
    });
  }
  return await ChatClient.get<ConversationHistory>(`/assistants/${assistantCode}/sessions?pageIndex=1&pageSize=50`);
}
