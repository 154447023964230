export function readLocalStorage(key: string): string | null {
  return localStorage.getItem(key);
}

export function setLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function deleteLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function readLocalStorageOrThrow(key: string): string {
  const value = readLocalStorage(key);
  if (!value) {
    throw new Error(`Cannot retrieve the key "${key}" from the local storage.`);
  }
  return value;
}
