import { getSessionHeaders } from '../session-storage';
import { DEFAULT_ERROR_MESSAGE } from './http-error-mapper';

type PostBody = Record<string, unknown> | FormData | unknown[];

export class HttpClient {
  constructor(private readonly baseUrl: string, private readonly tokenProvider?: () => Promise<string>) {}

  public get<T>(path: string): Promise<HttpResponse<T>> {
    return this.tryExecute<T>(path, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  }

  public post<T>(path: string, body: PostBody, headers?: HeadersInit): Promise<HttpResponse<T>> {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return this.tryExecute<T>(path, {
      method: 'POST',
      headers: headers || defaultHeaders,
      body: body instanceof FormData ? body : JSON.stringify(body),
    });
  }

  public delete<T>(path: string, body?: unknown[]): Promise<HttpResponse<T>> {
    const headers: HeadersInit = {
      Accept: 'application/json',
    };

    if (body) {
      headers['Content-Type'] = 'application/json';
    }

    return this.tryExecute<T>(path, {
      method: 'DELETE',
      headers,
      body: body !== undefined ? JSON.stringify(body) : undefined,
    });
  }

  public put<T>(path: string, body: Record<string, unknown>): Promise<HttpResponse<T>> {
    return this.tryExecute<T>(path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  public patch<T>(path: string, body: Record<string, unknown>): Promise<HttpResponse<T>> {
    return this.tryExecute<T>(path, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  private async tryExecute<T>(path: string, config: RequestInit) {
    try {
      if (typeof this.tokenProvider === 'function') {
        config.headers = { ...config.headers, Authorization: `Bearer ${await this.tokenProvider()}` };
      }
      // Add session storage headers
      const sessionHeaders = getSessionHeaders();
      config.headers = { ...config.headers, ...sessionHeaders };
      const response = await fetch(`${this.baseUrl}${path}`, config);

      // Fetch resolves failing requests, so we need to check the status code
      if (!response.ok) {
        const errorResponse: HttpError = await response.json(); // Unfortunately, fetch is not generic
        return {
          data: undefined,
          error: errorResponse,
        };
      }

      const data = (await response.json()) as T; // Unfortunately, fetch is not generic
      return {
        data,
      };
    } catch (error) {
      const defaultError: HttpError = { type: 'Unknown error', title: DEFAULT_ERROR_MESSAGE };
      return {
        data: undefined,
        error: defaultError,
      };
    }
  }
}

export type HttpResponse<T> = {
  error?: HttpError;
  data: T | undefined;
};

export type HttpError = {
  /**
   * [Trimble HTTP API Standard, Standard Error Payload Schema](https://api-standards.trimble-pnp.com/api-standard/http#standard-error-payload-schema)
   */
  type: string;
  title: string;
  status?: number;
  instance?: string;
  detail?: string;
  errors?: { type: string; title: string; instance?: string; detail?: string }[];
  fault?: { code: string; description: string; message: string; status: string };
};
