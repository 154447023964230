import { PromptActionTypes, PromptActions } from './prompt-actions';

export type PromptState = {
  promptHistoryIndex: number;
  isInputEdited: boolean;
  prompt: string;
  promptHistory: string[];
};

export const initialPromptState: PromptState = {
  promptHistoryIndex: -1,
  isInputEdited: false,
  prompt: '',
  promptHistory: [],
};

export const promptReducer = (state: PromptState, action: PromptActions): PromptState => {
  switch (action.type) {
    case PromptActionTypes.SetPromptHistoryIndex: {
      if (action.payload === state.promptHistory.length || action.payload < -1) {
        return state;
      }
      return {
        ...state,
        promptHistoryIndex: action.payload,
        prompt: action.payload > -1 ? state.promptHistory[action.payload] : '',
      };
    }
    case PromptActionTypes.ResetPrompt: {
      return {
        ...state,
        prompt: '',
        promptHistoryIndex: -1,
        isInputEdited: false,
      };
    }
    case PromptActionTypes.UpdatePrompt: {
      return {
        ...state,
        prompt: action.payload,
        promptHistoryIndex: action.payload === '' ? -1 : state.promptHistoryIndex,
        isInputEdited: action.payload !== '',
      };
    }
    case PromptActionTypes.UpdatePromptHistory: {
      return {
        ...state,
        promptHistory: action.payload,
        promptHistoryIndex: -1,
      };
    }
    default:
      return state;
  }
};
