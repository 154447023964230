import { useEffect } from 'react';
import { AppState } from '../store/application-reducer';

export function useConversationScrolling(ref: React.RefObject<HTMLDivElement>, state: AppState, scrollToEnd: boolean) {
  useEffect(() => {
    if (ref.current && scrollToEnd) {
      // Scroll to the bottom of the conversation.
      ref.current.scrollTop = ref.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentConversation]);
}
