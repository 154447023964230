import { ProductInfo } from '../domain-models/product-info.model';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';

export async function onStartConversation(dispatch: React.Dispatch<ApplicationActions>): Promise<void> {
  dispatch({ type: AppActionTypes.START_CONVERSATION });
}

export async function onStartProductConversation(
  dispatch: React.Dispatch<ApplicationActions>,
  agent: ProductInfo
): Promise<void> {
  dispatch({ type: AppActionTypes.START_PRODUCT_CONVERSATION, payload: agent });
}
