import { ApiUrls, HttpClient, HttpResponse } from '@tda/utilities';

export class ChatClient {
  public static instance = new HttpClient(ApiUrls.local);

  public static setInstance(baseUrl: string, tokenProvider?: () => Promise<string>) {
    this.instance = new HttpClient(baseUrl, tokenProvider);
  }

  public static get<T>(path: string): Promise<HttpResponse<T>> {
    return this.instance.get<T>(path);
  }

  public static post<T>(path: string, body: Record<string, unknown>): Promise<HttpResponse<T>> {
    return this.instance.post<T>(path, body);
  }

  public static patch<T>(path: string, body: Record<string, unknown>): Promise<HttpResponse<T>> {
    return this.instance.patch<T>(path, body);
  }

  public static put<T>(path: string, body: Record<string, unknown>): Promise<HttpResponse<T>> {
    return this.instance.put<T>(path, body);
  }

  public static delete<T = void>(path: string): Promise<HttpResponse<T>> {
    return this.instance.delete<T>(path);
  }
}
