import { fetchConversation } from '../api-interaction/fetch-conversation';
import { ConversationEntry } from '../domain-models/conversation-entry.model';
import { Conversation } from '../domain-models/conversation.model';
import { AppActionTypes, ApplicationActions } from '../store/application-actions';

export async function onLoadConversation(
  conversationToLoad: string,
  currentlyLoadedConversation: string,
  assistantCode: string,
  dispatch: React.Dispatch<ApplicationActions>
): Promise<void> {
  if (conversationToLoad === currentlyLoadedConversation || !assistantCode) {
    // We already have this conversation loaded, so we don't need to do anything.
    return;
  }

  dispatch({ type: AppActionTypes.LOAD_CONVERSATION, payload: conversationToLoad });
  const { data, error } = await fetchConversation(conversationToLoad, assistantCode);

  if (!data || error) {
    dispatch({ type: AppActionTypes.LOADING_CONVERSATION_FAILED });
    return;
  }
  const conversation: Conversation = {
    pageIndex: data.pageIndex,
    totalItems: data.totalItems,
    messages: toMessageArray(data.messages),
  };

  dispatch({ type: AppActionTypes.CONVERSATION_LOADED, payload: conversation });
}

function toMessageArray(messageMap: { [conversationId: string]: ConversationEntry }) {
  return Object.values(messageMap).sort(sortByDate);
}

function sortByDate(a: ConversationEntry, b: ConversationEntry): number {
  return a.timestamp - b.timestamp;
}
