import { deleteSessionStorage, setSessionStorage, updateSessionStorage } from '@tda/utilities';
import { TrimbleAuthCodeGrantProvider } from '@technology/auth-code-grant-provider';
import { buildUpdatedUrl } from 'libs/utilities/src/search-params';
import * as ReactDOM from 'react-dom/client';
import Loading from './app/Loading';
import { TransportationUserProvider } from './app/pages/TransportationUserProvider';
import { environment } from './environments/environment';

// Render the app
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Loading />);

// Access the current page URL
const currentUrl = window.location.href;
const url = new URL(currentUrl);
const params = new URLSearchParams(url.search);
const authz = params.get('x-authz');

if (authz) {
  const sessionParams = [
    { key: 'access_token', value: authz },
    { key: 'userId', value: params.get('x-user-id') },
    { key: 'authzIdp', value: params.get('x-authz-idp') },
    { key: 'userSource', value: params.get('x-user-source') },
    { key: 'userProducts', value: params.get('x-products') },
    { key: 'userTheme', value: params.get('x-theme') },
  ];
  updateSessionStorage(sessionParams);

  // Event Listener for refresh token
  window.addEventListener('message', (event) => {
    if (event.data?.type === 'tmtCompassRefreshToken' || event.data?.type === 'compassRefreshToken') {
      console.log(`Received ${event.data?.type} event`);
      const refreshParams = event.data.data;
      const updatedUrl = buildUpdatedUrl(refreshParams);
      window.location.href = updatedUrl;
    }
  });
  const userInfoProvider = new TransportationUserProvider(environment.env);
  userInfoProvider.getUserInfo(authz).then((user) => {
    setSessionStorage('userProductId', user.sub);
  });

  root.unmount();
  import('./bootstrap').then(() => {});
} else {
  const sessionKeys = [
    'access_token',
    'userId',
    'authzIdp',
    'userSource',
    'userProducts',
    'userTheme',
    'userProductId',
  ];
  sessionKeys.forEach((key) => deleteSessionStorage(key));

  TrimbleAuthCodeGrantProvider.autoInit(
    {
      consumerKey: environment.CONSUMER_KEY,
      openIdConfigUrl: environment.OPENID_CONFIG_URL,
      logoutUrl: environment.logoutUrl,
      redirectUrl: window.location.origin,
      requestScope: environment.REQUESTED_SCOPE,
      identityProvider: environment.identityProvider,
    },
    () => {
      root.unmount();
      import('./bootstrap').then(() => {
        // Intentionally empty
        // Async import to avoid eager loading
      });
    },
    new TransportationUserProvider(environment.env),
    false
  );
}
